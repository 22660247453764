export const DEFAULT_IS_SCROLL = true
export const MANUAL_FILTER_KEYS = {
  store: "store",
}
export const INITIAL_PAGE = 1
export const INITIAL_PER_PAGE = 18

export enum TogglePageMethod {
  SWITCH = "switch",
  ADDITIONAL = "additional",
}
