import { FC } from "react";
import { cx } from "@linaria/core";
import { PushCounter } from "@/components/PushCounter";
import { useAuth } from "@/hooks/auth";
import { useCompares } from "@/hooks/compares";
import { Button } from "@/ui/Button/Button";
import { cssButtonToCompares } from "@/ui/Button/StyledButton";
import { ROUTES } from "@/utils/constants";
export const ButtonToCompares: FC = () => {
  const {
    quantity
  } = useCompares();
  const {
    isInit,
    isAuth
  } = useAuth();
  return <Button variant={"box"} icon={"CompareAdd"} className={cx(cssButtonToCompares)} as={"a"} href={isInit && isAuth ? `${ROUTES.account}${ROUTES.compares}` : `${ROUTES.compares}`} aria-label={"Перейти в сравнение"} seoText={"Перейти в сравнение"}>
      <PushCounter qty={quantity} />
    </Button>;
};
ButtonToCompares.displayName = "ButtonToCompares";