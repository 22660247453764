import { useState } from "react";
import type { AppContext } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { Router } from "next/router";
import NProgress from "nprogress"; //nprogress module
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "nprogress/nprogress.css";
import { Provider as ReakitIdProvider } from "reakit";
import { Provider as AppProvider } from "../hooks/app";
import { Provider as UserProvider } from "../hooks/auth";
import { Provider as CartProvider } from "../hooks/cart/cart";
import { Provider as ComparesProvider } from "../hooks/compares";
import { Provider as FavoritesProvider } from "../hooks/favorites";
import { fetchCategories } from "@/api/catalogAPI";
import { fetchIpLocate } from "@/api/dadataAPI";
import { Fonts } from "@/components/Fonts";
import { TITLE_DEFAULT } from "@/components/Meta/constants";
import { Notifications } from "@/components/Notifications";
import { AuthGuard } from "@/hoc/AuthGuard";
import { Provider as ModalsProvider } from "@/hooks/modals/context";
import { usePreserveScroll } from "@/hooks/preserveScroll";
import { DefaultLayout } from "@/layouts/Default/DefaultLayout";
import { setLocation } from "@/store/reducers/appSlice";
import { setBusinessAreas, setCategories } from "@/store/reducers/categoriesSlice";
import { wrapper } from "@/store/store";
import { breakpoints, colors, fontDefault, fontSizeDefault } from "@/styles/utils/vars";
import type { AppPropsWithLayout, LocationType, PagePropsType } from "@/types";
import { LOCATION_NAME_COOKIE_KEY } from "@/utils/constants";
import { getExpireOneYear, getFormatResponseLocation, getIp, parseCookies } from "@/utils/helpers";
import "styles/scss/globals.scss";
const AppScripts = dynamic(() => import("@/components/AppScripts").then(m => m.AppScripts));
NProgress.configure({
  showSpinner: false
});
Router.events.on("routeChangeStart", () => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
  NProgress.done();
});
Router.events.on("routeChangeError", () => {
  NProgress.done();
});
function GrosterApp({
  Component,
  pageProps
}: AppPropsWithLayout): JSX.Element {
  const withLayout = Component.getLayout || (page => <DefaultLayout>{page}</DefaultLayout>);
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        retry: 1,
        retryDelay: 100,
        keepPreviousData: true
      },
      mutations: {
        retry: 1,
        retryDelay: 100
      }
    }
  }));
  usePreserveScroll();
  return <>
      <style jsx global={true}>{`
        #__next {
          height: 100%;
          position: relative;
        }

        html {
          font-family: ${fontDefault};
          color: ${colors.black};
          font-size: ${fontSizeDefault};
          height: 100%;
        }

        html,
        body {
          font-size: 16px;
          line-height: 187%;
          font-weight: 500;
          padding: 0;
          margin: 0;
          -webkit-text-size-adjust: none;
          text-rendering: optimizeSpeed;
          // обязательно width 100% при открытии модальных окон
          // - body становится fixed, на ios все ломается
          width: 100%;
        }

        html #nprogress .bar {
          background: ${colors.brand.purple};
          height: 8px;
        }

        html #nprogress .peg {
          box-shadow: 0 0 10px ${colors.brand.purple},
            0 0 5px ${colors.brand.purpleDarken};
        }

        html #nprogress .spinner-icon {
          border-top-color: ${colors.brand.purple};
          border-left-color: ${colors.brand.purple};
        }

        @media (max-width: ${breakpoints.md}) {
          html #nprogress .bar {
            background: ${colors.white};
          }

          html #nprogress .peg {
            box-shadow: 0 0 10px ${colors.white}, 0 0 5px ${colors.white};
          }
        }

        body {
          padding-bottom: env(safe-area-inset-bottom);
          -webkit-overflow-scrolling: touch;
          position: relative;
          top: 0;
        }

        *,
        *:before,
        *:after {
          box-sizing: border-box;
          outline: none;
        }

        * {
          outline: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        img {
          max-width: 100%;
        }

        a {
          text-decoration: none;
          color: ${colors.brand.purple};
          -webkit-tap-highlight-color: transparent;
        }

        a:hover,
        a:active {
          color: ${colors.brand.purpleDarken};
        }

        label {
          -webkit-tap-highlight-color: transparent;
        }

        .grecaptcha-badge {
          z-index: 10;
          display: none !important;
        }
        input:focus,
        textarea:focus,
        select:focus,
        button:focus,
        img:focus {
          outline: none;
        }

        jdiv[class^="wrap"],
        jdiv[class^="globalClass"] > jdiv {
          z-index: 100 !important;
        }

        jdiv.__jivoMobileButton {
          margin-bottom: 100px !important;
        }
      `}</style>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>{TITLE_DEFAULT}</title>
        <Fonts />
      </Head>

      <QueryClientProvider client={queryClient}>
        <Hydrate state={(pageProps as PagePropsType)?.dehydratedState}>
          <ReakitIdProvider>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ""} scriptProps={{
            appendTo: "body",
            id: "reCaptchaG",
            async: true
          }}>
              <AppProvider>
                <ModalsProvider>
                  <UserProvider>
                    <CartProvider>
                      <FavoritesProvider>
                        <ComparesProvider>
                          {Component.requireAuth ? <AuthGuard>
                              {withLayout(<Component {...pageProps} />)}
                            </AuthGuard> : <>{withLayout(<Component {...pageProps} />)}</>}
                          <Notifications />
                        </ComparesProvider>
                      </FavoritesProvider>
                    </CartProvider>
                  </UserProvider>
                </ModalsProvider>
              </AppProvider>
            </GoogleReCaptchaProvider>
          </ReakitIdProvider>
        </Hydrate>
        <ReactQueryDevtools />
      </QueryClientProvider>
      <AppScripts />
    </>;
}
GrosterApp.getInitialProps = wrapper.getInitialAppProps(store => async (appContext: AppContext) => {
  const {
    ctx: {
      req,
      res,
      pathname,
      query
    },
    Component
  } = appContext;
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({
      ...appContext,
      pathname,
      query,
      store
    });
  }
  const queryClient = new QueryClient();
  let madeCookies = res?.getHeader("Set-Cookie") || "";
  if (!!req && !!res) {
    const parsedCookie = parseCookies(req);
    let location: null | LocationType = null;
    const locationCookie = !!parsedCookie ? parsedCookie[LOCATION_NAME_COOKIE_KEY] || "" : "";
    if (!locationCookie) {
      const ip = getIp(req);
      if (!!ip) {
        const ipLocateResult = await queryClient.fetchQuery(["ipLocate"], () => fetchIpLocate(ip));
        if (!!ipLocateResult && !!ipLocateResult.location) {
          location = getFormatResponseLocation(ipLocateResult.location.data);
          madeCookies += `${LOCATION_NAME_COOKIE_KEY}=${encodeURIComponent(JSON.stringify({
            ...location,
            is_guessed: true
          }))}; Path=/; Expires=${getExpireOneYear().toUTCString()}`;
        }
      }
    } else {
      location = JSON.parse(locationCookie);
    }
    store.dispatch(setLocation(location));
  }

  // Content-Disposition : form-data; name="field_value"
  madeCookies += `host=${encodeURIComponent(req?.headers.host || "")}; Path=/; Expires=${getExpireOneYear().toUTCString()}`;
  res?.setHeader("Set-Cookie", madeCookies);
  const categoriesData = await queryClient.fetchQuery(["categories"], () => fetchCategories({
    server: true
  }));
  if (!!categoriesData) {
    const {
      categories,
      bussinessAreas
    } = categoriesData;
    store.dispatch(setCategories(categories || []));
    store.dispatch(setBusinessAreas(bussinessAreas || []));
  }
  return {
    pageProps: pageProps
  };
});
export default wrapper.withRedux(GrosterApp);