import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export const enum modalsAlias {
  becomePartner = "becomePartner", // used on page /take_lm2 /partner
  registerOnSale = "registerOnSale", // used on page /closed_sale
  takeNote = "takeNote", // used on page /lm1 /lm2
  implementAuth = "implementAuth",
  auth = "auth"
}

const initialStateModal = {
  isShow: false,
}

const initialState = {
  modals: {
    [modalsAlias.becomePartner]: initialStateModal,
    [modalsAlias.registerOnSale]: initialStateModal,
    [modalsAlias.takeNote]: initialStateModal,
    [modalsAlias.implementAuth]: initialStateModal,
    [modalsAlias.auth]: initialStateModal,
  },
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsShowModal: (
      state,
      {
        payload,
      }: PayloadAction<{
        isShow: boolean
        alias: modalsAlias
      }>,
    ) => {
      state.modals[payload.alias].isShow = payload.isShow
    },
  },
})

export const { setIsShowModal } = commonSlice.actions
