import {
  CartResponse,
  ChangeQtyRequest,
  DeleteProductRequest,
  FastOrderRequest,
} from "../../contracts/contracts"
import { deleteFetch, get, post, put } from "../service/fetcher"
import {
  ChangeQtyProductResponse,
  ChangeQtyResponse,
  ChangeQtySampleResponse,
  FreeShippingReturnType,
} from "../types/types"

export const fetchChangeQtyProduct = (
  changeQtyData: ChangeQtyRequest,
): Promise<ChangeQtyProductResponse> =>
  post<ChangeQtyProductResponse, ChangeQtyRequest>(
    "/cart/change-qty",
    changeQtyData,
    true,
  )

export const fetchCart = (tokenCart: string): Promise<CartResponse> =>
  get<CartResponse>(`/cart/${tokenCart}`, true)

export const fetchRemoveProduct = (
  request: DeleteProductRequest,
): Promise<ChangeQtyResponse> =>
  deleteFetch<ChangeQtyResponse, DeleteProductRequest>(
    `/cart/delete-product`,
    request,
    true,
  )

export const fetchChangeQtySample = (
  productData: ChangeQtyRequest,
): Promise<ChangeQtySampleResponse> =>
  post<ChangeQtySampleResponse, ChangeQtyRequest>(
    "/cart/sample",
    productData,
    true,
  )

export const fetchClearCart = (data: { uid: string }): Promise<boolean> =>
  put<boolean, { uid: string }>(`/cart/remove/${data.uid}`, data, true)

export const fetchRemoveCart = (data: { uid: string }): Promise<boolean> =>
  deleteFetch<boolean, { uid: string }>(`/cart/remove/${data.uid}`, data, true)

export const fetchBindUserCart = (data: { cart: string }): Promise<boolean> =>
  put(`/cart/${data.cart}/bind-user`, null, true)

export const fetchFastOrder = ({
  cart,
  ...data
}: { cart: string, code: string } & FastOrderRequest): Promise<{ number: string }> =>
  put(`/fast-order/${cart}`, data, true)

export const fetchFreeShipping = (
  region: string,
): Promise<FreeShippingReturnType> => get(`/free-shipping?region=${region}`)
