import {
  AccountRequestUpdatePassword,
  AddressType,
  Payer,
  V1AccountUpdateFioUpdatePayload,
} from "../../contracts/contracts"
import { ISendRecentProducts } from "@/hooks/watchedRecent/types"
import { get, post, put } from "@/service/fetcher"
import { PayerListItemType } from "@/types"

export const fetchPayers = (): Promise<PayerListItemType[]> =>
  get("/account/payers", true)

export const fetchPayerCreate = (data: Payer): Promise<{ uid: string }> =>
  post("/account/payer/add", data, true)

export const fetchPayerEdit = ({
  uid,
  ...data
}: Payer & { uid: string }): Promise<{ uid: string }> =>
  put(`/account/payer/${uid}`, data, true)

export const fetchPayerByUid = (uid: string): Promise<Payer> =>
  get(`/account/payer/${uid}`, true)

export const fetchAddresses = (): Promise<(AddressType & { uid: string })[]> =>
  get("/account/addresses", true)

export const fetchAddressCreate = (data: {
  name: string
  address: string
  comment?: string
}): Promise<{ uid: string }> =>
  post<
    { uid: string },
    {
      name: string
      address: string
      comment?: string
    }
  >("/account/address/add", data, true)

export const fetchAddressEdit = ({
  uid,
  ...data
}: Omit<AddressType, "uid"> & { uid: string }): Promise<{ uid: string }> =>
  put(`/account/address/${uid}`, data, true)

export const fetchSetAddressDefault = ({
  uid,
}: {
  uid: string
}): Promise<{ uid: string }> =>
  put(`/account/address/${uid}/default`, null, true)

export const fetchUpdatePassword = (
  data: AccountRequestUpdatePassword,
): Promise<null> => put("/account/update-password", data, true)

export const fetchUpdateFio = (
  data: V1AccountUpdateFioUpdatePayload,
): Promise<null> => put("/account/update-fio", data, true)

type DocumentResponseType = { link: string } | null

export const fetchAttachedContractPayers = ({
  uid,
}: {
  uid: string
}): Promise<DocumentResponseType> =>
  get(`/account/order/${uid}/attached-documents-contractpayer`, true)

export const fetchGenerateContractPayers = ({
  uid,
}: {
  uid: string
}): Promise<DocumentResponseType> =>
  get(`/account/order/${uid}/generate-document-contractpayer`, true)

export const fetchGenerateInvoicePayment = ({
  uid,
}: {
  uid: string
}): Promise<DocumentResponseType> =>
  get(`/account/order/${uid}/generate-document-invoice-payment`, true)

export const fetchGeneratePackingList = ({
  uid,
}: {
  uid: string
}): Promise<DocumentResponseType> =>
  get(`/account/order/${uid}/generate-document-packing-list`, true)

export const fetchRecommendations = (): Promise<string[] | null> =>
  get("/account/recommendations", true)

export type RecentProductsReturnType = { products: string[] } | null
export const fetchSendRecentProducts = (
  // отправляю id недавно просмотренных товаров
  data: ISendRecentProducts,
): Promise<RecentProductsReturnType> =>
  post("/account/recent-products", data, true)
