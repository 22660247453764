import { FC } from "react"
import { Icon } from "../Icon"
import { StyledNoImageContainer } from "./Styled"

const NoImage: FC = () => {
  return (
    <StyledNoImageContainer>
      <Icon NameIcon={"NoImage"} size={"fill"} />
    </StyledNoImageContainer>
  )
}

export { NoImage }
