import { BaseHTMLAttributes, forwardRef, MouseEvent, useMemo } from "react";
import { cx } from "@linaria/core";
import { Button } from "../Button/Button";
import { Icon } from "../Icon";
import { SelectedHint, SelectedHintBody } from "../Select/StyledSelect";
import { ErrorMessageField, Typography } from "../Typography/Typography";
import { cssButtonFieldClear, Placeholder, StyledFieldInput, StyledFieldWrapper, StyledHint, StyledInput } from "./StyledField";
import { FieldVariantsPropsType } from "@/types";
export interface IAutoComplete {
  autoCompleteContent?: React.ReactElement<any> | null;
}
export const InputField = forwardRef<HTMLInputElement, FieldVariantsPropsType & IAutoComplete & Pick<BaseHTMLAttributes<HTMLInputElement>, "className">>(({
  autoCompleteContent = null,
  name,
  errorMessage,
  withAnimatingLabel = true,
  placeholder,
  iconButton,
  withButton,
  textButton,
  iconPositionButton = "right",
  variantButton = "box",
  isFetching,
  onClickButton,
  disabledButton,
  iconField,
  hint,
  classNameButton,
  className,
  withClean = false,
  cleanCb,
  required = false,
  isVisibleSelectedHint = false,
  selectedValue,
  seoTextButton,
  ...props
}, ref) => {
  const ariaLabel = useMemo(() => variantButton === "box" ? (props || {})["aria-label"] || placeholder : textButton, [variantButton, textButton, placeholder]);
  return <StyledFieldWrapper data-isanimating-label={withAnimatingLabel} data-iswith-button={withButton} data-iserror={!!errorMessage} data-required={required}>
        <StyledFieldInput>
          <StyledInput name={name} ref={ref} placeholder={withAnimatingLabel ? " " : placeholder} className={className} {...props} />

          {withAnimatingLabel && <Placeholder htmlFor={props.id}>{placeholder}</Placeholder>}
          {withClean && <>
              <Button variant={"box"} icon={"X"} type={"button"} size={"medium"} className={cx(cssButtonFieldClear)} aria-label={"Очистить"} seoText={"Очистить"} onClick={e => {
          e.preventDefault();
          if (cleanCb) {
            cleanCb();
          }
        }} />
            </>}
          {withButton && <Button variant={variantButton} icon={iconButton} iconPosition={iconPositionButton} isFetching={isFetching} disabled={disabledButton} className={classNameButton} onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (onClickButton) {
          onClickButton(e);
        }
      }} aria-label={ariaLabel} seoText={seoTextButton}>
              {textButton}
            </Button>}
          {iconField !== undefined && <Icon NameIcon={iconField} />}

          {autoCompleteContent}
        </StyledFieldInput>

        {hint && <StyledHint>{hint}</StyledHint>}

        {!!errorMessage && <ErrorMessageField>{errorMessage}</ErrorMessageField>}

        {isVisibleSelectedHint && selectedValue !== undefined && <>
            <SelectedHint>
              <Typography variant={"p12"}>
                <b>Выбран: </b>
              </Typography>
              <SelectedHintBody>{selectedValue}</SelectedHintBody>
            </SelectedHint>
          </>}
      </StyledFieldWrapper>;
});
InputField.displayName = "InputField";