import { ComparesResponseReturnType } from "../../../contracts"
import {
  COMPARES_EXPIRES_PRODUCTS_COOKIE_KEY,
  COMPARES_PRODUCTS_COOKIE_KEY,
  COMPARES_SELECTED_CATEGORY_NAME_COOKIE_KEY,
} from "@/hooks/compares/constants"
import { ComparesPayloadType } from "@/hooks/compares/types"
import { cookies, getExpireCompares } from "@/utils/helpers"

export const setComparesSelectedCategoryCookie = (
  selectedCategory: string | null,
): void => {
  if (!!selectedCategory) {
    cookies.set(
      COMPARES_SELECTED_CATEGORY_NAME_COOKIE_KEY,
      JSON.stringify(selectedCategory),
      {
        path: "/",
      },
    )
  } else {
    cookies.remove(COMPARES_SELECTED_CATEGORY_NAME_COOKIE_KEY)
  }
}

export const getComparesSelectedCategoryStorage = (): string | null => {
  return cookies.get(COMPARES_SELECTED_CATEGORY_NAME_COOKIE_KEY) || null
}

export const getValidatedSelectedCategory = ({
  category,
  comparesPayload,
}: {
  category?: string | null
  comparesPayload: ComparesPayloadType | null
}) => {
  const c = category
  if (c === null) {
    return c
  }

  if (!comparesPayload) {
    return null
  }

  if (!!c && !!comparesPayload[c]) {
    return c
  } else {
    const keys = Object.keys(comparesPayload)
    return keys[keys.length - 1] || null
  }
}

export const getComparesStorage = (): ComparesResponseReturnType[] | null => {
  const data: ComparesResponseReturnType[] | undefined = cookies.get(
    COMPARES_PRODUCTS_COOKIE_KEY,
  )

  return data || null
}

export const getComparesExpiresStorage = (): string | null => {
  return cookies.get(COMPARES_EXPIRES_PRODUCTS_COOKIE_KEY) || null
}

export const setComparesExpiresStorage = (date: Date | null) => {
  if (!date) {
    cookies.remove(COMPARES_EXPIRES_PRODUCTS_COOKIE_KEY)
    return
  }
  cookies.set(COMPARES_EXPIRES_PRODUCTS_COOKIE_KEY, JSON.stringify(date), {
    path: "/",
    expires: date,
  })
}

export const setComparesStorage = (
  uuids: ComparesResponseReturnType[] | null,
): Date | undefined => {
  if (!uuids) {
    return
  }

  if (!uuids.length) {
    cookies.remove(COMPARES_PRODUCTS_COOKIE_KEY)
    return
  }

  const date = getExpireCompares()
  cookies.set(COMPARES_PRODUCTS_COOKIE_KEY, JSON.stringify(uuids), {
    path: "/",
    expires: date,
  })
  if (!getComparesExpiresStorage() && !!date) {
    setComparesExpiresStorage(date)
  }
}
