import type { FC } from "react"
import { StyledPushCounter } from "@/components/PushCounter/StyledPushCounter"
import { LIMIT_PUSH_COUNTER_DEFAULT } from "@/utils/constants"

const PushCounter: FC<{
  qty: number | null
}> = ({ qty, children }) => {
  if (!(!!qty && qty > 0)) {
    return null
  }
  return (
    <StyledPushCounter>
      {qty > LIMIT_PUSH_COUNTER_DEFAULT
        ? `${LIMIT_PUSH_COUNTER_DEFAULT}+`
        : qty}
      {children}
    </StyledPushCounter>
  )
}

export default PushCounter
