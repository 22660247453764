import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import {
  CategoryByAreaResponse,
  ParamsResponse,
  SortTypeResponse,
} from "../../../contracts"
import { RootStateType } from "../store"
import { BreadcrumbItemType } from "@/components/Breadcrumbs/BreadcrumbItem"
import { ProductType } from "@/components/Products/types"
import {
  INITIAL_PAGE,
  INITIAL_PER_PAGE,
  MANUAL_FILTER_KEYS,
  TogglePageMethod,
} from "@/hooks/filterCatalog/constants"
import { ShopType } from "@/hooks/shops/types"
import {
  CatalogResponseCategoryType,
  CatalogResponseSSRType,
  CategoriesStateType,
  IAvailableParam,
  IAvailableParams,
  ICategoryTreeItem,
  IFilterParamsChilds,
  IFilterParamsParents,
  InitializeCatalogPayloadType,
  PriceRangeItemType,
  QueryCatalogType,
  SortByAliasType,
  SortByType,
} from "@/types"
import { EMPTY_DATA_PLACEHOLDER, ROUTES } from "@/utils/constants"
import {
  compareSortTypesWithIcon,
  createCategoriesTree,
  getFilterParamsTree,
  getSubItemsBreadcrumbs,
  makeAvailableFilters,
  normalizeCategoriesByAreas,
} from "@/utils/helpers"

export type StatePriceRangeType = {
  initial: PriceRangeItemType
  checked: PriceRangeItemType
  last: PriceRangeItemType | null
  isSelected: boolean
}

export type ViewPageProductsType = "grid" | "list"

const initialState = {
  categoriesByAreas: [] as ICategoryTreeItem[],
  currentCategory: null as CatalogResponseCategoryType | null,
  breadcrumbs: null as Record<string, BreadcrumbItemType> | null,
  products: null as ProductType[] | null,
  total: 0 as number,
  params: {} as IFilterParamsParents & IFilterParamsChilds,
  filter: {
    availableParams: null as IAvailableParams | null,
    checkedParamsKeysTotal: [] as string[],
    priceRange: {
      initial: {
        min: 0,
        max: 0,
      },
      // текущая, выбранная пользователем
      // или пришедшая из запроса фильтрованного каталога
      checked: {
        min: 0,
        max: 0,
      },
      // крайняя цена, которая пришла из запроса
      // сравнивается с checked ценой
      // если они равны,
      // значит пользователь не изменял цену - то checked не нужно отправлять в апи
      // иначе, она измененная и пользователь ограничил рамки цены
      last: null,
      isSelected: false,
    } as StatePriceRangeType,
    stores: {
      params: null as IAvailableParams | null,
    },
    query: {
      initial: null as QueryCatalogType | null,
      maked: null as QueryCatalogType | null,
    },
    minQuantity: {
      initial: null as number | null,
      selected: null as number | null,
    },
  },
  isEnabled: true as boolean,
  isFast: false as boolean,
  sortBy: null as SortByType,
  sortBySelected: null as SortByAliasType | null,
  page: INITIAL_PAGE as number,
  itemsPerPage: null as number | null,
  isLoading: false,
  togglePageMethod: TogglePageMethod.SWITCH as TogglePageMethod,
  viewProducts: "grid" as ViewPageProductsType,
  popup: {
    isShow: false as boolean,
  },
}

export const catalogSlice = createSlice({
  name: "catalog",
  initialState: initialState,
  reducers: {
    setProducts(state, action: PayloadAction<ProductType[]>) {
      if (state.togglePageMethod === TogglePageMethod.ADDITIONAL) {
        state.products = [...(state.products || []), ...action.payload]
      } else {
        state.products = action.payload
      }
    },
    setFilterParams(state, { payload }: PayloadAction<ParamsResponse>) {
      const result = getFilterParamsTree(payload)
      window["filterparams"] = result
      state.params = result
    },
    setAvailableParams(
      state,
      action: PayloadAction<{
        catalogParams: Record<string, number>
        queryParams: string[]
      } | null>,
    ) {
      if (action.payload !== null) {
        const { catalogParams, queryParams } = action.payload
        state.filter.availableParams = makeAvailableFilters({
          stateParams: state.params,
          catalogParams,
          queryParams,
        })
      } else {
        state.filter.availableParams = action.payload
      }
    },
    setCurrentCategory(
      state,
      action: PayloadAction<CatalogResponseSSRType["category"]>,
    ) {
      state.currentCategory = action.payload
    },
    updateCheckedParams(
      state,
      action: PayloadAction<{
        keyFilter: string[]
        checked: boolean
      }>,
    ) {
      if (!!state.filter && !!state.filter.availableParams) {
        const { keyFilter, checked } = action.payload
        for (const key of keyFilter) {
          if (!state.filter.availableParams) {
            continue
          }

          const parent: IAvailableParam =
            state.filter.availableParams[state.params[key]?.parentUuid || ""]

          if (!parent) {
            continue
          }

          if (!!parent?.params[key]) {
            parent.params[key].checked = checked
          }

          if (checked) {
            parent.checkedKeys = [...(parent?.checkedKeys || []), key].reduce(
              (uniq: string[], item) => {
                return uniq.includes(item) ? uniq : [...uniq, item]
              },
              [],
            )
          } else {
            parent.checkedKeys =
              parent?.checkedKeys.filter((k) => k !== key) || []
          }
        }
      }
    },
    setInitialPriceRange(
      state,
      { payload }: PayloadAction<PriceRangeItemType>,
    ) {
      state.filter.priceRange.initial = payload
    },
    setCheckedPriceRange(
      state,
      action: PayloadAction<PriceRangeItemType | null>,
    ) {
      if (action.payload === null) {
        state.filter.priceRange.checked = state.filter.priceRange.initial
        state.filter.priceRange.last = state.filter.priceRange.initial
        state.filter.priceRange.isSelected = false
      } else {
        const { min: payloadMin, max: payloadMax } = action.payload
        const { min: initialMin, max: initialMax } =
          state.filter.priceRange.initial

        state.filter.priceRange.checked = action.payload
        state.filter.priceRange.last = action.payload
        state.filter.priceRange.isSelected =
          payloadMin !== initialMin || payloadMax !== initialMax
      }
    },
    setLastPriceRange(
      state,
      { payload }: PayloadAction<PriceRangeItemType | null>,
    ) {
      if (!payload) {
        state.filter.priceRange.last = null
      } else {
        state.filter.priceRange.last = payload
      }
    },
    setCheckedParamsKeysTotal(state, action: PayloadAction<string[]>) {
      state.filter.checkedParamsKeysTotal = action.payload
    },
    setIsEnabled(state, action: PayloadAction<boolean>) {
      state.isEnabled = action.payload
    },
    setIsFast(state, action: PayloadAction<boolean>) {
      state.isFast = action.payload
    },
    setSortBy(state, action: PayloadAction<SortTypeResponse>) {
      state.sortBy = compareSortTypesWithIcon(action.payload)
    },
    setSortBySelected(state, action: PayloadAction<null | SortByAliasType>) {
      state.sortBySelected = action.payload
    },
    setInitMinQuantity(state, action: PayloadAction<number | null>) {
      state.filter.minQuantity.initial = action.payload
    },
    setSelectedMinQuantity(state, action: PayloadAction<number | null>) {
      state.filter.minQuantity.selected = action.payload
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.total = action.payload
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setItemsPerPage(state, action: PayloadAction<number>) {
      state.itemsPerPage = action.payload
    },
    setTogglePageMethod(state, action: PayloadAction<TogglePageMethod>) {
      state.togglePageMethod = action.payload
    },
    setCategoriesByAreas(
      state,
      {
        payload,
      }: PayloadAction<{
        categoryByAreas: CategoryByAreaResponse | undefined
        categoriesFetched: CategoriesStateType["fetched"]
      }>,
    ) {
      if (!!payload.categoryByAreas) {
        if (!!payload.categoriesFetched) {
          const { treeSorted } = createCategoriesTree(
            normalizeCategoriesByAreas(
              payload.categoryByAreas,
              payload.categoriesFetched,
            ),
          )
          state.categoriesByAreas = treeSorted
        }
      }
    },
    setFilterStores(
      state,
      { payload }: PayloadAction<Record<string, ShopType>>,
    ) {
      const stores: IAvailableParams = {}
      stores[MANUAL_FILTER_KEYS.store] = {
        uuid: MANUAL_FILTER_KEYS.store,
        name: "Склады",
        order: 0,
        checkedKeys: [],
        params: Object.fromEntries(
          Object.entries(payload).map(([uuid, s]) => {
            return [
              uuid,
              {
                uuid: uuid,
                name: s.address || EMPTY_DATA_PLACEHOLDER,
                parentUuid: MANUAL_FILTER_KEYS.store,
                product_qty: undefined,
                disabled: false,
                checked: false,
              },
            ]
          }),
        ),
      }
      state.filter.stores.params = stores
    },
    updateCheckedStores(
      state,
      action: PayloadAction<{
        keyFilter: string[]
        checked: boolean
      }>,
    ) {
      const { keyFilter, checked } = action.payload
      if (state.filter.stores.params !== null) {
        keyFilter.map((key) => {
          if (state.filter.stores.params !== null) {
            if (
              !!state.filter.stores.params[MANUAL_FILTER_KEYS.store].params[key]
            ) {
              state.filter.stores.params[MANUAL_FILTER_KEYS.store].params[
                key
              ].checked = checked

              if (checked) {
                state.filter.stores.params[
                  MANUAL_FILTER_KEYS.store
                ].checkedKeys = [
                  ...state.filter.stores.params[MANUAL_FILTER_KEYS.store]
                    .checkedKeys,
                  key,
                ].reduce((uniq: string[], item) => {
                  return uniq.includes(item) ? uniq : [...uniq, item]
                }, [])
              } else {
                state.filter.stores.params[
                  MANUAL_FILTER_KEYS.store
                ].checkedKeys = state.filter.stores.params[
                  MANUAL_FILTER_KEYS.store
                ].checkedKeys.filter((k) => k !== key)
              }
            }
          }
        })
      }
    },
    updateBreadcrumbs(
      state,
      {
        payload: { category, categories },
      }: PayloadAction<{
        category: Pick<
          CatalogResponseCategoryType,
          "parent" | "uuid" | "alias" | "name" | "id"
        >
        categories: CategoriesStateType | null
      }>,
    ) {
      if (!categories) {
        state.breadcrumbs = null
        return
      }

      if (!categories.fetched || !categories.treeCompared) {
        state.breadcrumbs = null
        return
      }

      const breadcrumbs = {} as Record<string, BreadcrumbItemType>

      const { uuid = "", alias = "", name, id = "", parent = "" } = category

      const sequenceParent = [...(categories.treeCompared[uuid] || [])]

      let treeSlice = null as ICategoryTreeItem | null

      for (const item of sequenceParent) {
        const foundCategory = categories.fetched[item.uuid || ""]

        if (treeSlice === null) {
          treeSlice =
            categories.treeSorted.find((t) => t.uuid === item.uuid) || null
        } else {
          treeSlice = (treeSlice?.children || {})[item.uuid || ""] || null
        }

        breadcrumbs[foundCategory.uuid || ""] = {
          path: `${ROUTES.catalog}/${foundCategory.alias || ""}`,
          title: `${foundCategory.name}`,
          subItems: getSubItemsBreadcrumbs(treeSlice),
        }
      }

      if (!parent) {
        treeSlice =
          !!categories.treeSorted && !!uuid
            ? categories.treeSorted.find((t) => t.uuid === uuid) || null
            : null
      } else {
        treeSlice = (treeSlice?.children || {})[id] || null
      }

      breadcrumbs[uuid] = {
        path: `${ROUTES.catalog}/${alias}`,
        title: `${name}`,
        subItems: getSubItemsBreadcrumbs(treeSlice),
      }

      state.breadcrumbs = breadcrumbs
    },
    setQueryMaked: (state, action: PayloadAction<QueryCatalogType | null>) => {
      state.filter.query.maked = action.payload
    },
    setQueryInitial: (
      state,
      action: PayloadAction<QueryCatalogType | null>,
    ) => {
      state.filter.query.initial = action.payload
    },
    setPopupIsShow: (state, { payload }: PayloadAction<boolean>) => {
      state.popup.isShow = payload
    },
    setViewProducts: (
      state,
      { payload }: PayloadAction<ViewPageProductsType>,
    ) => {
      state.viewProducts = payload
    },
    initializeCatalog: (
      state,
      {
        payload: { category, query },
      }: PayloadAction<InitializeCatalogPayloadType>,
    ) => {
      state.currentCategory = category.category
      state.total = category.total
      state.page = !query?.page ? INITIAL_PAGE : +query.page
      state.itemsPerPage = +(query?.per_page || INITIAL_PER_PAGE)
      state.products =
        state.togglePageMethod === TogglePageMethod.ADDITIONAL
          ? [...(state.products || []), ...category.products]
          : category.products
      state.filter.minQuantity.initial = !!query?.min_quantity
        ? +query.min_quantity
        : null
      state.isEnabled = query?.is_enabled !== "0"
      state.isFast = query.is_fast === undefined ? false : query.is_fast !== "0"
      state.filter.query.initial = query
    },
  },
  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      const { params, ...payload } = action.payload
        .catalog as RootStateType["catalog"]
      return {
        ...state,
        ...payload,
      }
    })
  },
})

export const {
  setCategoriesByAreas,
  updateBreadcrumbs,
  setCurrentCategory,
  setCurrentPage,
  setTogglePageMethod,
  setFilterParams,
  updateCheckedParams,
  setInitialPriceRange,
  setCheckedPriceRange,
  setLastPriceRange,
  setSortBy,
  setAvailableParams,
  setSortBySelected,
  setInitMinQuantity,
  setSelectedMinQuantity,
  setCheckedParamsKeysTotal,
  setFilterStores,
  updateCheckedStores,
  setQueryMaked,
  setPopupIsShow,
  setViewProducts,
  initializeCatalog,
} = catalogSlice.actions
