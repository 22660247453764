import { FC, useState, useEffect, useCallback, useMemo } from "react"
import dynamic, { DynamicOptions } from "next/dynamic"
import LazyHydrate from "react-lazy-hydration"
import type { LocationListPropsType } from "@/components/LocationList"
import { useApp } from "@/hooks/app"
import { LocationType } from "@/types"
import { Button } from "@/ui/Button/Button"
import { ButtonGroup } from "@/ui/Button/StyledButton"
import { Modal } from "@/ui/Modal/Modal"
import { Popover } from "@/ui/Popover/Popover"
import { Typography } from "@/ui/Typography/Typography"
import { LOCATION_DEFAULT } from "@/utils/constants"
import { getLocationCookie, setLocationCookie } from "@/utils/helpers"

type LocationPopoverPropsType = {
  location: LocationType | null
  isShowPopover: boolean
  isShowLocations: boolean
  showModal: () => void
  fastYesHandle: () => void
  onSelectLocationHandle: (location: LocationType | null) => void
  setIsShowLocations: (value: boolean) => void
}

const LocationList = dynamic(
  (() =>
    import("@/components/LocationList").then(
      (mod) => mod.LocationList,
    )) as DynamicOptions<LocationListPropsType>,
  {
    ssr: false,
  },
)

export const LocationPopover: FC<LocationPopoverPropsType> = ({
  location,
  isShowPopover,
  isShowLocations,
  showModal,
  fastYesHandle,
  onSelectLocationHandle,
  setIsShowLocations,
}) => {
  return (
    <>
      {isShowPopover && (
        <Popover
          disclosure={<Button />}
          isShow={isShowPopover}
          size={"default"}
          isHiddenDisclosure
          hideOnClickOutside={false}
        >
          <Typography variant={"p14"}>
            Ваш город {(location || LOCATION_DEFAULT).city}?
          </Typography>
          <ButtonGroup>
            <Button
              variant={"filled"}
              size={"small"}
              onClick={fastYesHandle}
              aria-label={"Местоположение по-умолчанию"}
              type="button"
              role="button"
            >
              Да
            </Button>
            <Button
              variant={"outline"}
              size={"small"}
              onClick={showModal}
              aria-label={"Другое местоположение"}
              type="button"
              role="button"
            >
              Другой
            </Button>
          </ButtonGroup>
        </Popover>
      )}

      <Modal
        closeMode={"destroy"}
        title={"Выберите свой город"}
        variant={"rounded-50"}
        isShowModal={isShowLocations}
        onClose={() => {
          setIsShowLocations(false)
        }}
      >
        <LazyHydrate on="click">
          <LocationList
            currentLocation={location || undefined}
            onSelectLocation={onSelectLocationHandle}
          />
        </LazyHydrate>
      </Modal>
    </>
  )
}

export const useLocationPopover = () => {
  const { location, updateLocation } = useApp()
  const [isShowPopover, setIsShowPopover] = useState<boolean>(false)
  const [isShowLocations, setIsShowLocations] = useState<boolean>(false)

  const showModal = useCallback(() => {
    setIsShowLocations(true)
  }, [setIsShowLocations])

  const fastYesHandle = useCallback(() => {
    const location = getLocationCookie()
    updateLocation(!!location ? { ...location, is_guessed: false } : null)
    setIsShowPopover(false)
  }, [updateLocation])

  const onSelectLocationHandle = useCallback(
    (location: LocationType | null) => {
      updateLocation(!!location ? { ...location, is_guessed: false } : null)
      setIsShowPopover(false)
    },
    [updateLocation],
  )

  const hidePopover = useCallback(() => {
    setIsShowPopover(false)
  }, [setIsShowPopover])

  useEffect(() => {
    const location = getLocationCookie()

    if (!!location) {
      const isGuessed = location.is_guessed

      if (isGuessed) {
        setIsShowPopover(true)
      }
    } else {
      setLocationCookie(LOCATION_DEFAULT)
      setIsShowPopover(true)
    }
  }, [location])

  return useMemo(
    () => ({
      location,
      isShowPopover,
      isShowLocations,
      showModal,
      fastYesHandle,
      onSelectLocationHandle,
      setIsShowLocations,
      hidePopover,
    }),
    [
      location,
      isShowPopover,
      isShowLocations,
      showModal,
      fastYesHandle,
      onSelectLocationHandle,
      setIsShowLocations,
      hidePopover,
    ],
  )
}
