import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ComparesResponseReturnType } from "../../../contracts"
import { ProductListResponse } from "../../../contracts/src/api/product"
import { getValidatedSelectedCategory } from "@/hooks/compares/helpers"
import {
  ComparePropertiesType,
  ComparesPayloadType,
} from "@/hooks/compares/types"
import { IFilterParamsChilds, IFilterParamsParents } from "@/types"

const initialStateProducts = {
  uuids: [],
  properties: null,
  data: [],
  visible: [],
  isLoading: false,
}

const initialState = {
  payload: null as ComparesPayloadType | null,
  products: {
    uuids: [] as string[],
    properties: null as ComparePropertiesType | null,
    data: [] as ProductListResponse,
    visible: [] as string[],
    isLoading: false,
  },
  selectedCategory: null as string | null,
  showDiff: false as boolean,
  storageExpiresDate: null as string | null,
}

export const comparesSlice = createSlice({
  name: "compares",
  initialState,
  reducers: {
    setComparesPayload: (
      state,
      {
        payload: { response, selectedCategory },
      }: PayloadAction<{
        response: ComparesResponseReturnType[]
        selectedCategory?: string
      }>,
    ) => {
      const data = {}
      let products: string[] = []

      if (!response.length) {
        state.payload = data
        state.products = { ...initialStateProducts, uuids: products }
        state.selectedCategory = null
        return
      }

      for (const item of response) {
        data[item.uuid] = { ...item }
        products = [...products, ...item.products]
      }

      state.payload = data
      state.products.uuids = products.reduce((uniq: string[], item) => {
        return uniq.includes(item) ? uniq : [...uniq, item]
      }, [])
      state.selectedCategory = getValidatedSelectedCategory({
        comparesPayload: state.payload,
        // по-умолчанию активируем последнюю категорию из энпоинта
        // (она же будет первой, тк выводим на фронте через reverse)
        category:
          selectedCategory || response[response.length - 1].uuid || null,
      })
    },
    updateComparesPayloadByProduct: (
      state,
      {
        payload,
      }: PayloadAction<{
        product: string
        category: {
          uuid: string
          name: string
          selected?: string
        }
      }>,
    ) => {
      const {
        product: uuidProduct,
        category: {
          uuid: uuidCategory,
          name: nameCategory,
          selected: selectedCategory,
        },
      } = payload

      if (!state.payload) {
        state.payload = {}
      }

      state.payload[uuidCategory] = {
        ...(state.payload[uuidCategory] || {}),
        uuid: uuidCategory,
        name: nameCategory,
        products: [
          ...(state.payload[uuidCategory]?.products || []),
          uuidProduct,
        ],
      }
      state.products.uuids = [...state.products.uuids, uuidProduct].reduce(
        (uniq: string[], item) => {
          return uniq.includes(item) ? uniq : [...uniq, item]
        },
        [],
      )
      state.selectedCategory = getValidatedSelectedCategory({
        comparesPayload: state.payload,
        // по-умолчанию активируем последнюю категорию из энпоинта
        // (она же будет первой, тк выводим на фронте через reverse)
        category: selectedCategory || null,
      })
    },
    addProductUUID: (state, { payload }: PayloadAction<string[]>) => {
      state.products.uuids = [...state.products.uuids, ...payload].reduce(
        (uniq: string[], item) => {
          return uniq.includes(item) ? uniq : [...uniq, item]
        },
        [],
      )
    },
    removeProductUUID: (state, { payload }: PayloadAction<string[]>) => {
      const selectedCategory = state.selectedCategory

      if (!!selectedCategory && !!state.payload) {
        const currentPayloadCategory = state.payload[selectedCategory]
        const stPayload = { ...state.payload }
        const products = currentPayloadCategory.products.filter(
          (uuid) => !payload.includes(uuid),
        )

        console.log("new products ", products)
        if (!!products.length) {
          stPayload[currentPayloadCategory.uuid] = {
            ...currentPayloadCategory,
            products: products,
          }
        } else {
          delete stPayload[currentPayloadCategory.uuid]
        }

        const newSelectedCategory = getValidatedSelectedCategory({
          comparesPayload: stPayload,
          category: selectedCategory,
        })
        state.payload = newSelectedCategory === null ? {} : stPayload
        state.selectedCategory = newSelectedCategory
      }

      state.products.uuids = state.products.uuids.filter(
        (uuid) => !payload.includes(uuid),
      )
      state.products.data = state.products.data.filter(
        (p) => !payload.includes(p.uuid || ""),
      )
      state.products.visible = state.products.visible.filter(
        (p) => !payload.includes(p),
      )
    },
    setSelectedCategory: (
      state,
      { payload: category }: PayloadAction<string | null>,
    ) => {
      state.selectedCategory = getValidatedSelectedCategory({
        comparesPayload: state.payload,
        category,
      })
    },
    setProperties: (
      state,
      {
        payload,
      }: PayloadAction<{
        products: ProductListResponse | null
        params: (IFilterParamsParents & IFilterParamsChilds) | null
        uuidsVisible?: string[]
      }>,
    ) => {
      const { params, products, uuidsVisible = [] } = payload

      if (!products?.length || !params || !Object.keys(params || {}).length) {
        state.products.properties = null
        return
      }

      const matchingProductParams: Record<
        string,
        Record<
          string,
          {
            product: string
            parent: string | undefined
          }
        >
      > = {}
      const uuidsFiltersOnly: Set<string> = new Set()

      //собираем сопоставление товаров
      // с параметрами и их родителями (фильтрами)
      products.forEach((p) => {
        matchingProductParams[p.uuid || ""] = (p.params || []).reduce(
          (o, key) => {
            const uuidParent = params[key]?.parentUuid
            if (!!uuidParent) {
              uuidsFiltersOnly.add(uuidParent)
            }
            return {
              ...o,
              [key]: {
                product: p.uuid,
                parent: uuidParent,
              },
            }
          },
          {},
        )
      })

      // собираем главную структуру
      // - сами фильтры (Цвет, Размер и тп)
      // - товары, конкретной категории для этих фильтров
      // (с учетом их наличия или нет и разного количества в одном товаре)
      const unionProductsFilters: ComparePropertiesType = {}
      for (const uuid of uuidsFiltersOnly) {
        const filter = params[uuid]
        if (!filter) {
          continue
        }
        const productsArr: {
          product: string
          params: { uuid: string; name: string }[]
        }[] = Object.entries(matchingProductParams).map(
          ([uuidProduct, paramsObj]) => {
            const paramsComputed = Object.entries(paramsObj)
              .filter(([, param]) => param.parent === uuid)
              .map(([uuid]) => ({ uuid, name: params[uuid].name || "" }))

            return {
              product: uuidProduct,
              params: paramsComputed,
            }
          },
        )

        let productsVisible: {
          product: string
          params: { uuid: string; name: string }[]
        }[] = []
        let diff = false
        let diffVisible = false

        const productsObj = productsArr.reduce(
          (o, itemProduct, currentIndex, sourceArray) => {
            if (!diff && currentIndex > 0) {
              diff = !(
                itemProduct.params.map((p) => p.name).join(",") ===
                sourceArray[currentIndex - 1].params
                  .map((p) => p.name)
                  .join(",")
              )
            }

            if (uuidsVisible.includes(itemProduct.product)) {
              productsVisible = [...productsVisible, itemProduct]
            }

            return { ...o, [itemProduct.product]: itemProduct.params }
          },
          {},
        )

        productsVisible.reduce(
          (acc, itemProduct, currentIndex, sourceArray) => {
            if (!diffVisible && currentIndex > 0) {
              diffVisible = !(
                itemProduct.params.map((p) => p.name).join(",") ===
                sourceArray[currentIndex - 1].params
                  .map((p) => p.name)
                  .join(",")
              )
            }

            return []
          },
          [],
        )

        unionProductsFilters[uuid] = {
          uuid: uuid,
          name: filter.name || "",
          products: productsObj,
          diff: diff,
          diffVisible: diffVisible,
        }
      }

      // console.log("unionProductsFilters ", unionProductsFilters)
      // console.log("matchingProductParams ", matchingProductParams)
      state.products.properties = unionProductsFilters
    },
    setShowDiff: (state, { payload }: PayloadAction<boolean>) => {
      state.showDiff = payload
    },
    clearCompares: (state) => {
      state.payload = {}
      state.products = initialStateProducts
      state.selectedCategory = null
      state.storageExpiresDate = null
    },
    setProductsData: (
      state,
      { payload }: PayloadAction<ProductListResponse>,
    ) => {
      state.products.data = payload
    },
    setProductsVisible: (state, { payload }: PayloadAction<string[]>) => {
      state.products.visible = payload
    },
    setProductsIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.products.isLoading = payload
    },
    setStorageExpiresDate: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.storageExpiresDate = payload
    },
  },
})

export const {
  setComparesPayload,
  updateComparesPayloadByProduct,
  addProductUUID,
  removeProductUUID,
  setSelectedCategory,
  setProperties,
  setShowDiff,
  clearCompares,
  setProductsData,
  setProductsVisible,
  setProductsIsLoading,
  setStorageExpiresDate,
} = comparesSlice.actions
