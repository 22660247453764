import { configureStore } from "@reduxjs/toolkit"
import { createWrapper } from "next-redux-wrapper"
import { combineReducers } from "redux"
import { accountSlice } from "./reducers/accountSlice"
import { appSlice } from "./reducers/appSlice"
import { cartSlice } from "./reducers/cartSlice"
import { catalogSlice } from "./reducers/catalogSlice"
import { categoriesSlice } from "./reducers/categoriesSlice"
import { commonSlice } from "./reducers/commonSlice"
import { notificationsSlice } from './reducers/notificationsSlice'
import { searchSlice } from "./reducers/searchSlice"
import { shopSlice } from "./reducers/shopSlice"
import { comparesSlice } from "@/store/reducers/comparesSlice"

const rootReducer = combineReducers({
  [appSlice.name]: appSlice.reducer,
  [accountSlice.name]: accountSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [categoriesSlice.name]: categoriesSlice.reducer,
  [catalogSlice.name]: catalogSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [shopSlice.name]: shopSlice.reducer,
  [commonSlice.name]: commonSlice.reducer,
  [comparesSlice.name]: comparesSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer
})

export const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
  })

export const wrapper = createWrapper<AppStoreType>(makeStore, { debug: false })

export type RootStateType = ReturnType<typeof rootReducer>
export type AppStoreType = ReturnType<typeof makeStore>
export type AppDispatch = AppStoreType["dispatch"]
