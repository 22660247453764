import { FC, SVGProps } from "react"
import AngleBottom from "./svg/angleBottom.svg"
import AngleLeft from "./svg/angleLeft.svg"
import AngleRight from "./svg/angleRight.svg"
import AngleTop from "./svg/angleTop.svg"
import ArrowCircleRight from "./svg/arrowCircleRight.svg"
import ArrowDown from "./svg/arrowDown.svg"
import ArrowLeft from "./svg/arrowLeft.svg"
import ArrowRight from "./svg/arrowRight.svg"
import ArrowUp from "./svg/arrowUp.svg"
import Bolt from "./svg/bolt.svg"
import Book from "./svg/book.svg"
import CalendarEdit from "./svg/calendarEdit.svg"
import Car from "./svg/car.svg"
import Catalog from "./svg/catalog.svg"
import Chat from "./svg/chat.svg"
import Check from "./svg/check.svg"
import CircleLoading from "./svg/circleLoading.svg"
import CompareActive from "./svg/compareListActive.svg"
import CompareAdd from "./svg/compareListAdd.svg"
import Copy from "./svg/copy.svg"
import Delete from "./svg/delete.svg"
import Download from "./svg/download.svg"
import Edit from "./svg/edit.svg"
import Email from "./svg/email.svg"
import Facebook from "./svg/fb.svg"
import File from "./svg/file.svg"
import FileRemove from "./svg/fileRemove.svg"
import Filter from "./svg/filter.svg"
import Flag from "./svg/flag.svg"
import Google from "./svg/google.svg"
import GrosterUi from "./svg/grosterUi.svg"
import Heart from "./svg/heart.svg"
import HeartFilled from './svg/heartFilled.svg'
import Help from "./svg/help.svg"
import Home from "./svg/home.svg"
import InBrowser from "./svg/inBrowser.svg"
import Info from "./svg/info.svg"
import Instagram from "./svg/inst.svg"
import Layers from "./svg/layers.svg"
import Link from "./svg/link.svg"
import ListBullet from "./svg/listBullet.svg"
import Location from "./svg/location.svg"
import Lock from "./svg/lock.svg"
import Menu from "./svg/menu.svg"
import Minus from "./svg/minus.svg"
import MoreHor from "./svg/moreHor.svg"
import NearMe from "./svg/nearMe.svg"
import NoImage from "./svg/noimage.svg"
import Orders from "./svg/orderList.svg"
import PayApple from "./svg/payApple.svg"
import PayGoogle from "./svg/payGoogle.svg"
import PayMaestro from "./svg/payMaestro.svg"
import PayMastercard from "./svg/payMastercard.svg"
import PayMir from "./svg/payMir.svg"
import PayVisa from "./svg/payVisa.svg"
import Phone from "./svg/phone.svg"
import Pinterest from "./svg/pinterest.svg"
import Plus from "./svg/plus.svg"
import PlusCircle from "./svg/plusCircle.svg"
import PlusSquare from "./svg/plusSquare.svg"
import Print from "./svg/print.svg"
import Puzzle from "./svg/puzzle.svg"
import Ratio from "./svg/ratio.svg"
import Refresh from "./svg/refresh.svg"
import RefreshTime from "./svg/refreshTime.svg"
import Remove from "./svg/remove.svg"
import Rotate from "./svg/rotate.svg"
import Search from "./svg/search.svg"
import Share from "./svg/share.svg"
import ShoppingCart from "./svg/shoppingCart.svg"
import Star from "./svg/star.svg"
import StarOutline from "./svg/starOutline.svg"
import StatsFew from "./svg/statsFew.svg"
import StatsMany from "./svg/statsMany.svg"
import StatsNot from "./svg/statsNot.svg"
import Tag from "./svg/tag.svg"
import Taxi from "./svg/taxi.svg"
import Telegram from "./svg/telegram.svg"
import Transaction from "./svg/transaction.svg"
import Trophy from "./svg/trophy.svg"
import Twitter from "./svg/twitter.svg"
import Undo from "./svg/undo.svg"
import Union from "./svg/union.svg"
import User from "./svg/user.svg"
import UserAdd from "./svg/userAdd.svg"
import Verified from "./svg/verified.svg"
import Viber from "./svg/viber.svg"
import ViewBoxes from "./svg/viewBoxes.svg"
import ViewSchedule from "./svg/viewSchedule.svg"
import Vk from "./svg/vk.svg"
import Wallet from "./svg/wallet.svg"
import Warning from "./svg/warning.svg"
import WhatsApp from "./svg/whatsApp.svg"
import X from "./svg/x.svg"
import Youtube from "./svg/youtube.svg"
import ZoomIn from "./svg/zoomIn.svg"
import { IconNameType } from "./index"

type IconWithStylesPropsType = {
  NameIcon: IconNameType
}

export const matching = {
  Copy: () => Copy,
  AngleRight: () => AngleRight,
  AngleBottom: () => AngleBottom,
  Check: () => Check,
  ArrowDown: () => ArrowDown,
  ArrowUp: () => ArrowUp,
  ArrowRight: () => ArrowRight,
  ArrowLeft: () => ArrowLeft,
  ArrowCircleRight: () => ArrowCircleRight,
  Plus: () => Plus,
  Minus: () => Minus,
  ViewBoxes: () => ViewBoxes,
  ViewSchedule: () => ViewSchedule,
  Layers: () => Layers,
  Tag: () => Tag,
  StatsFew: () => StatsFew,
  StatsNot: () => StatsNot,
  StatsMany: () => StatsMany,
  Car: () => Car,
  Star: () => Star,
  StarOutline: () => StarOutline,
  X: () => X,
  Info: () => Info,
  Search: () => Search,
  ShoppingCart: () => ShoppingCart,
  Lock: () => Lock,
  PayApple: () => PayApple,
  PayGoogle: () => PayGoogle,
  PayMastercard: () => PayMastercard,
  PayMaestro: () => PayMaestro,
  PayVisa: () => PayVisa,
  PayMir: () => PayMir,
  Phone: () => Phone,
  Chat: () => Chat,
  Email: () => Email,
  Location: () => Location,
  Instagram: () => Instagram,
  Youtube: () => Youtube,
  Facebook: () => Facebook,
  Twitter: () => Twitter,
  Telegram: () => Telegram,
  Pinterest: () => Pinterest,
  Vk: () => Vk,
  Google: () => Google,
  WhatsApp: () => WhatsApp,
  Viber: () => Viber,
  Menu: () => Menu,
  Filter: () => Filter,
  User: () => User,
  Share: () => Share,
  Print: () => Print,
  Delete: () => Delete,
  Transaction: () => Transaction,
  Undo: () => Undo,
  CircleLoading: () => CircleLoading,
  AngleLeft: () => AngleLeft,
  Union: () => Union,
  UserAdd: () => UserAdd,
  Edit: () => Edit,
  ListBullet: () => ListBullet,
  Verified: () => Verified,
  Flag: () => Flag,
  Puzzle: () => Puzzle,
  Ratio: () => Ratio,
  ZoomIn: () => ZoomIn,
  NoImage: () => NoImage,
  PlusCircle: () => PlusCircle,
  Wallet: () => Wallet,
  FileRemove: () => FileRemove,
  CalendarEdit: () => CalendarEdit,
  NearMe: () => NearMe,
  Remove: () => Remove,
  Book: () => Book,
  PlusSquare: () => PlusSquare,
  Heart: () => Heart,
  HeartFilled: () => HeartFilled,
  Trophy: () => Trophy,
  File: () => File,
  Download: () => Download,
  InBrowser: () => InBrowser,
  Refresh: () => Refresh,
  RefreshTime: () => RefreshTime,
  Warning: () => Warning,
  Bolt: () => Bolt,
  Link: () => Link,
  Rotate: () => Rotate,
  CompareAdd: () => CompareAdd,
  CompareActive: () => CompareActive,
  History: () => GrosterUi,
  Help: () => Help,
  Taxi: () => Taxi,
  Home: () => Home,
  Orders: () => Orders,
  Catalog: () => Catalog,
  AngleTop: () => AngleTop,
  MoreHor: () => MoreHor,
  default: () => Link,
}

export const IconInner: FC<
  IconWithStylesPropsType & SVGProps<SVGSVGElement>
> = ({ NameIcon = "default", ...props }) => {
  const founded = matching[NameIcon]

  if (!founded) {
    return null
  }

  const Component = founded()
  return <Component {...props} />
}

IconInner.displayName = "IconInner"

export type { IconWithStylesPropsType }
