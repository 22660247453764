import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { Category } from "../../../contracts"
import { CategoriesStateType } from "@/types"
import { createCategoriesTree } from "@/utils/helpers"

const initialState = {
  businessAreas: null as Category[] | null,
  categories: null as CategoriesStateType | null,
}
export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<Category[]>) {
      const { treeCompared, treeSorted } = createCategoriesTree(action.payload)

      state.categories = {
        treeSorted: treeSorted,
        treeCompared: treeCompared,
        fetched:
          action.payload &&
          (action.payload.reduce(
            (a, v) => ({ ...a, [v?.uuid || ""]: v }),
            {},
          ) as Record<string, Category>),
      }
    },
    setBusinessAreas(state, action: PayloadAction<Category[]>) {
      state.businessAreas = action.payload
    },
    initializeCategories(
      state,
      {
        payload: { categories, businessAreas },
      }: PayloadAction<{
        categories: Category[]
        businessAreas: Category[]
      }>,
    ) {
      const { treeCompared, treeSorted } = createCategoriesTree(categories)

      state.categories = {
        treeSorted: treeSorted,
        treeCompared: treeCompared,
        fetched:
          categories &&
          (categories.reduce(
            (a, v) => ({ ...a, [v?.uuid || ""]: v }),
            {},
          ) as Record<string, Category>),
      }
      state.businessAreas = businessAreas
    },
  },
  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload.categories,
      }
    })
  },
})

export const { setCategories, setBusinessAreas, initializeCategories } =
  categoriesSlice.actions
