import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useMutation } from "react-query"
import {
  fetchAddToFavorites,
  fetchFavorites,
  fetchRemoveAllFavorites,
  fetchRemoveToFavorites,
} from "@/api/favoritesAPI"
import { useAuth } from "@/hooks/auth"
import {
  getFavoritesStorage,
  setFavoritesStorage,
} from "@/hooks/favorites/helpers"
import { ContextPropsType } from "@/hooks/favorites/types"
import { NOTIFICATION_KIND } from "@/hooks/notification/constants"
import { useNotifications } from "@/hooks/notification/notification"
import { useAppDispatch, useAppSelector } from "@/hooks/redux"
import {
  addToFavorites,
  clearFavorites,
  removeFromFavorites,
  toggleFavoritesProgress,
} from "@/store/reducers/accountSlice"
import { readBooleanFromStorage } from "@/utils/helpers"

export const FavoritesContext = createContext<null | ContextPropsType>(null)
export const Provider: FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const { keys: favoritesKeys, toggleProgress: toggleProgressList } =
    useAppSelector((state) => state.profile.favorites)
  const { isAuth, isInit } = useAuth()
  const [isShowToLogin, setIsShowToLogin] = useState(false)
  const { push: pushNotification } = useNotifications()

  const addFavoritesToState = useCallback(
    (products: string[]) => {
      dispatch(addToFavorites(products))
    },
    [dispatch],
  )

  const { mutate: addToFavoritesMutate } = useMutation(fetchAddToFavorites, {
    onMutate: (request) => {
      dispatch(
        toggleFavoritesProgress({
          isFetching: true,
          product: request.products,
        }),
      )
    },
    onSuccess: (response, request) => {
      addFavoritesToState(request.products.split(","))
    },
    onSettled: (data, error, request) => {
      dispatch(
        toggleFavoritesProgress({
          isFetching: false,
          product: request.products,
        }),
      )
    },
  })

  const { mutate: removeFromFavoritesMutate } = useMutation(
    fetchRemoveToFavorites,
    {
      onMutate: (request) => {
        dispatch(
          toggleFavoritesProgress({
            isFetching: true,
            product: request.product,
          }),
        )
      },
      onSuccess: (response, request) => {
        dispatch(removeFromFavorites(request.product))
      },
      onSettled: (data, error, request) => {
        dispatch(
          toggleFavoritesProgress({
            isFetching: false,
            product: request.product,
          }),
        )
      },
    },
  )

  const { mutate: getFavoritesUserMutate } = useMutation(fetchFavorites, {
    onSuccess: (response) => {
      addFavoritesToState(response !== null ? response.products : [])
      bindFavorites()
    },
  })

  const { mutate: removeAllFavoritesMutate } = useMutation(
    fetchRemoveAllFavorites,
    {
      onSuccess: () => {
        dispatch(clearFavorites())
      },
    },
  )

  const addToFavoritesHandler = useCallback(
    (productUUIDs: string[], withPush = true) => {
      if (isAuth) {
        addToFavoritesMutate({ products: productUUIDs.join(",") })
      } else {
        addFavoritesToState(productUUIDs)

        // Старт если юзер не авторизован и добавил товар в избранное, после 1 клика предлагаем ему авторизоваться
        const isOfferToLogIn = readBooleanFromStorage(
          sessionStorage,
          "offerToLogInFavorites",
        )

        if (!isOfferToLogIn) {
          sessionStorage.setItem("offerToLogInFavorites", "true")
          setIsShowToLogin(true)
        }
        // Конец
      }

      if (withPush) {
        pushNotification({ kind: NOTIFICATION_KIND.FAVORITES })
      }
    },
    [addFavoritesToState, addToFavoritesMutate, isAuth, pushNotification],
  )

  const removeFromFavoritesHandler = useCallback(
    (productUUID: string) => {
      if (isAuth) {
        removeFromFavoritesMutate({ product: productUUID })
      } else {
        dispatch(removeFromFavorites(productUUID))
      }
    },
    [dispatch, isAuth, removeFromFavoritesMutate],
  )

  const bindFavorites = useCallback(() => {
    const favoritesBeforeAuth = getFavoritesStorage()
    if (favoritesBeforeAuth !== null && favoritesBeforeAuth.length > 0) {
      addToFavoritesHandler(favoritesBeforeAuth, false)
      setFavoritesStorage([])
    }
  }, [addToFavoritesHandler])

  const quantity = useMemo(
    () => (favoritesKeys !== null ? favoritesKeys.length : 0),
    [favoritesKeys],
  )

  const hideToLogin = () => {
    setIsShowToLogin(false)
  }

  const clear = useCallback(() => {
    if (!isInit) return

    if (isAuth) {
      removeAllFavoritesMutate()
    } else {
      dispatch(clearFavorites())
      setFavoritesStorage([])
    }
  }, [dispatch, isAuth, isInit, removeAllFavoritesMutate])

  useEffect(() => {
    if (isInit) {
      if (!isAuth) {
        setFavoritesStorage(favoritesKeys)
      }
    }
  }, [favoritesKeys, isAuth, isInit])

  useEffect(() => {
    if (isInit) {
      if (!isAuth) {
        addFavoritesToState(getFavoritesStorage() || [])
      } else {
        getFavoritesUserMutate()
      }
    }
  }, [addFavoritesToState, dispatch, getFavoritesUserMutate, isAuth, isInit])

  const contextValue = useMemo(
    () => ({
      add: addToFavoritesHandler,
      remove: removeFromFavoritesHandler,
      favoritesKeys,
      quantity: quantity,
      fetchingUUIds: toggleProgressList,
      isShowToLogin,
      hideToLogin,
      clear,
    }),
    [
      addToFavoritesHandler,
      removeFromFavoritesHandler,
      favoritesKeys,
      toggleProgressList,
      quantity,
      isShowToLogin,
      clear,
    ],
  )

  return (
    <FavoritesContext.Provider value={contextValue}>
      {children}
    </FavoritesContext.Provider>
  )
}
