import { useCallback, useEffect, useMemo, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../redux"
import {
  NotificationHidePropsType,
  NotificationPushPropsType,
  UseNotificationReturnType,
} from "@/hooks/notification/types"
import {
  hideNotification,
  pushNotification,
} from "@/store/reducers/notificationsSlice"

export const useNotifications = (): UseNotificationReturnType => {
  const notifications = useAppSelector(
    ({ notifications }) => notifications.notifications,
  )
  const prevNotificationLength = useRef<number>(notifications.length)

  const dispatch = useAppDispatch()

  const push = useCallback(
    ({ kind, hideTimeout = 3000 }: NotificationPushPropsType) => {
      console.log("push")
      const currentIndex = prevNotificationLength.current
      dispatch(
        pushNotification({
          index: currentIndex,
          kind: kind,
        }),
      )

      if (hideTimeout != null) {
        setTimeout(
          () => dispatch(hideNotification({ index: currentIndex })),
          hideTimeout,
        )
      }
    },
    [dispatch],
  )

  const hide = useCallback(
    ({ index }: NotificationHidePropsType) => {
      console.log("hide")
      dispatch(hideNotification({ index }))
    },
    [dispatch],
  )

  useEffect(() => {
    prevNotificationLength.current = notifications.length
  }, [notifications.length])

  return useMemo(
    () =>
      ({
        push: push,
        hide: hide,
      } as const),
    [hide, push],
  )
}
