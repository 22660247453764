import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { setPopupIsShow as setIsShowCatalogPopup } from "../../store/reducers/catalogSlice"
import { useDebounce } from "../debounce"
import { useAppDispatch, useAppSelector } from "../redux"
import { useWindowSize } from "../windowSize"
import { PRODUCT_FETCH_SIZE_MOBILE, PRODUCT_FETCH_SIZE_PC } from "./constants"
import {
  filterToQueryFormat,
  getHistorySaved,
  setHistorySaved,
} from "./helpers"
import {
  ExtendSearchResultType,
  QueryPageSearchType,
  SearchContextProviderPropsType,
  UpdateSearchPropsType,
  UseSearchReturnContextType,
  UseSearchReturnType,
} from "./types"
import { fetchAutoComplete, PAGE, SORT } from "@/api/digineticaAPI"
import {
  appendProducts,
  appendProductsData,
  PayloadDistinctType,
  PayloadSliderType,
  SearchHistoryItemType,
  setCategoriesAutoComplete,
  setCorrection,
  setCurrentQuery,
  setFacets,
  setFilter,
  setHistory,
  setIsInitAutoComplete,
  setIsNotResultAutoComplete,
  setIsShowAutoComplete,
  setIsShowFullScreenSearch,
  setIsUpdate,
  setPage,
  setProducts,
  setProductsAutoComplete,
  setProductsData,
  setQuery,
  setQueryAutoComplete,
  setSelectedFacets,
  setSort,
  setStsAutoComplete,
  setTapsAutoComplete,
  setTotal,
  setZeroQueries,
  updateFilter,
  UpdateFilterPropsType,
} from "@/store/reducers/searchSlice"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { ROUTES } from "@/utils/constants"
import { scrollBodyDisable, scrollBodyEnable } from "@/utils/helpers"

const SearchContext = createContext<null | UseSearchReturnContextType>(null)

const Provider: FC<SearchContextProviderPropsType> = ({
  children,
  defaultValue,
}) => {
  const {
    history,
    query,
    autoComplete,
    correction,
    products,
    total,
    zeroQueries,
    facets,
    productsData,
    page,
    filter,
    selectedFacets,
    sort,
    isUpdate,
    fullScreen,
    currentQuery,
  } = useAppSelector(({ search }) => search)

  const {
    isShow: isShowAC,
    isInit: isInitAC,
    isNotResult: isNotResultAC,
  } = autoComplete

  const defaultQuery = defaultValue?.initialQuery || null

  const debouncedCurrentQuery = useDebounce(currentQuery, 200)
  const lastQueryRef = useRef<null | string>(defaultQuery)

  const [isUseCorrection, setIsUseCorrection] = useState(false)
  const [isNotResults, setIsNotResults] = useState(false)
  const router = useRouter()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { width } = useWindowSize()

  const dispatch = useAppDispatch()

  const setIsInitAC = useCallback(
    (val: boolean) => {
      dispatch(setIsInitAutoComplete(val))
    },
    [dispatch],
  )

  const productSizeAutoComplete =
    width !== undefined && width <= getBreakpointVal(breakpoints.md)
      ? PRODUCT_FETCH_SIZE_MOBILE
      : PRODUCT_FETCH_SIZE_PC

  const { isLoading: isLoadingAutoComplete, remove: removeAutoComplete } =
    useQuery(
      [
        "searchAutocomplete",
        productSizeAutoComplete,
        debouncedCurrentQuery,
        isShowAC,
      ],
      () =>
        fetchAutoComplete({
          query: debouncedCurrentQuery || "",
          size: productSizeAutoComplete,
        }),
      {
        enabled:
          (lastQueryRef.current === null ||
            lastQueryRef.current !== debouncedCurrentQuery) &&
          (isShowAC || fullScreen.isShow),
        onSuccess: (response) => {
          if (response === undefined) {
            return
          }
          if (response !== null) {
            lastQueryRef.current = response.query
            dispatch(setQueryAutoComplete(response.query))
            dispatch(setProductsAutoComplete(response.products))
            dispatch(setCategoriesAutoComplete(response.categories))
            dispatch(
              setTapsAutoComplete(
                response.taps.length > 0 ? response.taps : [],
              ),
            )
            dispatch(
              setStsAutoComplete(response.sts.length > 0 ? response.sts : []),
            )

            dispatch(
              setIsNotResultAutoComplete(
                response.query !== null &&
                  response.query.length > 0 &&
                  !response.products.length &&
                  !response.sts.length &&
                  !response.taps.length,
              ),
            )
          } else {
            dispatch(setQueryAutoComplete(null))
            dispatch(setProductsAutoComplete([]))
            dispatch(setCategoriesAutoComplete([]))
            dispatch(setTapsAutoComplete([]))
            dispatch(setStsAutoComplete([]))
          }
        },
      },
    )

  const updateCurrentQuery = useCallback(
    (value: string) => {
      dispatch(setCurrentQuery(value))
    },
    [dispatch],
  )

  const autoCompleteRef = useRef<HTMLDivElement | null>(null)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const inputFocus = useCallback(() => {
    if (!inputRef.current) {
      document.getElementById("id-search")?.focus()
    } else {
      inputRef.current?.focus()
    }
  }, [])

  const showAutoComplete = useCallback(() => {
    inputFocus()
    dispatch(setIsShowCatalogPopup(false))
    dispatch(setIsShowAutoComplete(true))
    scrollBodyDisable()
  }, [dispatch, inputFocus])

  const hideAutoComplete = useCallback(
    ({
      _isUpdateQueryText = false,
    }: { _isUpdateQueryText?: boolean } | undefined = {}) => {
      dispatch(setIsShowAutoComplete(false))
      dispatch(setIsShowFullScreenSearch(false))

      scrollBodyEnable()

      if (!!_isUpdateQueryText) {
        if (query !== currentQuery && !isSubmitting) {
          updateCurrentQuery(query || "")
        }
      }
    },
    [dispatch, query, currentQuery, isSubmitting, updateCurrentQuery],
  )

  const isAppendProducts = useRef<boolean>(false)

  const setSearchResult = useCallback(
    (search: ExtendSearchResultType | null) => {
      lastQueryRef.current = search?.query || ""
      dispatch(setCorrection(search?.correction || null))
      if (isAppendProducts.current) {
        dispatch(appendProducts(search?.products || []))
        dispatch(appendProductsData(search?.productsData || []))
      } else {
        dispatch(setProducts(search?.products || []))
        dispatch(setProductsData(search?.productsData || []))
      }
      dispatch(setTotal(search?.totalHits || 0))
      dispatch(setZeroQueries(!!search?.zeroQueries))
      dispatch(setQuery(search?.query || ""))
      dispatch(setFacets(search?.facets || []))
      dispatch(setSelectedFacets(search?.selectedFacets || []))
      dispatch(setPage(!!search?.page ? +search.page : PAGE))
      dispatch(setSort(!!search?.sortQuery ? search.sortQuery : SORT))
    },
    [dispatch],
  )

  const setSearchFilter = useCallback(
    (filterQuery: string[] | null) => {
      dispatch(setIsUpdate(false))

      if (filterQuery !== null) {
        const buildFilter: Record<string, string[]> = {}
        filterQuery.map((f) => {
          const item = f.split(":")
          const name = item[0]
          const values = item[1]
          if (!name || !values) {
            return
          }

          buildFilter[name] = values.split(";")
        })
        dispatch(setFilter(buildFilter))
      } else {
        dispatch(setFilter(null))
      }
    },
    [dispatch],
  )

  const clearSearchResult = useCallback(() => {
    removeAutoComplete()
    setSearchResult(null)
    updateCurrentQuery("")
    dispatch(setIsUpdate(false))
  }, [updateCurrentQuery, setSearchResult, dispatch, removeAutoComplete])

  const clearAutoComplete = useCallback(() => {
    updateCurrentQuery("")
    inputFocus()
  }, [updateCurrentQuery, inputFocus])

  const updateFilterHandle = useCallback(
    (props: UpdateFilterPropsType<PayloadDistinctType | PayloadSliderType>) => {
      dispatch(updateFilter(props))
      dispatch(setPage(PAGE))
      dispatch(setIsUpdate(true))
    },
    [dispatch],
  )

  const resetFilterHandle = useCallback(() => {
    dispatch(setFilter({}))
    dispatch(setPage(PAGE))
    dispatch(setIsUpdate(true))
  }, [dispatch])

  const updateSearch = useCallback(
    ({
      page: pageIn,
      filter: filterIn,
      query: queryIn,
      sort: sortIn,
      withReset = false,
      isAppend = false,
    }: UpdateSearchPropsType) => {
      isAppendProducts.current = isAppend
      let buildQuery: QueryPageSearchType

      if (queryIn !== undefined && withReset) {
        buildQuery = {
          query: queryIn,
        }
      } else {
        const p =
          pageIn !== undefined ? pageIn : page !== null ? page : undefined
        const s =
          sortIn !== undefined ? sortIn : sort !== null ? sort : undefined
        const f = filterToQueryFormat(
          filterIn !== undefined ? filterIn : filter,
        )
        buildQuery = {
          filter: f,
          query: queryIn || query || undefined,
          page: p !== undefined && p !== PAGE ? `${p}` : undefined,
          sort: s !== undefined && s !== SORT ? s : undefined,
        }
      }

      Object.keys(buildQuery).map((key) => {
        if (buildQuery[key] === undefined) {
          delete buildQuery[key]
        }
      })

      void router
        .replace(
          {
            pathname: `${ROUTES.search}`,
            query: buildQuery,
          },
          undefined,
          {
            scroll: !isAppend || !isAppendProducts.current,
            shallow: false,
          },
        )
        .catch((err) => console.log("error: ", err))
        .then(() => console.log("this will succeed"))
    },
    [page, sort, filter, query],
  )

  const appendHistoryItem = useCallback(
    (item: SearchHistoryItemType) => {
      const h = [...history, item].reduce(
        (uniq: SearchHistoryItemType[], item) => {
          return !!uniq.find((el) => el.query === item.query)
            ? uniq
            : [...uniq, item]
        },
        [],
      )

      dispatch(setHistory(h))
      setHistorySaved(h)
    },
    [dispatch, history],
  )

  const removeHistoryItem = useCallback(
    (item: SearchHistoryItemType) => {
      const h = history.filter((el) => el.query !== item.query)
      dispatch(setHistory(h))
      setHistorySaved(h)
    },
    [dispatch, history],
  )

  const clearHistory = useCallback(() => {
    dispatch(setHistory([]))
    setHistorySaved(null)
  }, [dispatch])

  const initDefaultValue = useCallback(() => {
    const { search, filterQuery, initialQuery } = defaultValue || {}
    if (initialQuery !== undefined) {
      const query = initialQuery || ""
      updateCurrentQuery(query)
      lastQueryRef.current = query
      dispatch(setQueryAutoComplete(query))
    }

    if (search !== undefined) {
      setSearchResult(search)
    }

    if (filterQuery !== undefined) {
      setSearchFilter(filterQuery)
    }
  }, [
    defaultValue,
    setSearchFilter,
    setSearchResult,
    dispatch,
    updateCurrentQuery,
  ])

  const existFilterQty = useMemo(
    () => Object.keys(filter || {}).length,
    [filter],
  )

  useEffect(() => {
    initDefaultValue()
  }, [initDefaultValue])

  useEffect(() => {
    if (isShowAC) {
      showAutoComplete()
    }
  }, [isShowAC])

  useEffect(() => {
    router.events.on("routeChangeComplete", hideAutoComplete)

    return () => {
      router.events.off("routeChangeComplete", hideAutoComplete)
    }
  }, [hideAutoComplete, router.events])

  useEffect(() => {
    if (filter !== null && isUpdate) {
      updateSearch({
        filter: filter,
      })
    }
  }, [filter, updateSearch, isUpdate])

  useEffect(() => {
    dispatch(setHistory(getHistorySaved()))
  }, [dispatch])

  useEffect(() => {
    setIsUseCorrection(!!correction && !!query && correction !== query)
  }, [correction, query])

  useEffect(() => {
    setIsNotResults(products.length <= 0 && zeroQueries)
  }, [products.length, zeroQueries])

  const contextValue = useMemo(
    () =>
      ({
        query: query,
        updateQueryText: updateCurrentQuery,
        appendToHistory: appendHistoryItem,
        removeFromHistory: removeHistoryItem,
        currentText: currentQuery,
        history: history,
        clearHistory,
        correction: correction,
        isUseCorrection,
        setSearchResult,
        products: products,
        total: total,
        zeroQueries,
        autoComplete: {
          products: autoComplete.products,
          brands: autoComplete.brands,
          taps: autoComplete.taps,
          sts: autoComplete.sts,
          categories: autoComplete.categories,
          query: autoComplete.query,
          contents: autoComplete.contents,
          isInit: isInitAC,
          setIsInit: setIsInitAC,
          ref: autoCompleteRef,
          isNotResult: isNotResultAC,
        },
        isNotResults,
        facets,
        productsData,
        clearSearchResult,
        clearAutoComplete,
        page,
        setSearchFilter,
        updateSearch,
        updateFilter: updateFilterHandle,
        isFilterUpdate: isUpdate,
        filter,
        selectedFacets,
        resetFilter: resetFilterHandle,
        setIsSubmitting,
        isSubmitting,
        hideAutoComplete,
        showAutoComplete,
        isShowAutoComplete: isShowAC,
        isLoadingAutoComplete,
        sort,
        fullScreen: fullScreen,
        inputRef,
        existFilterQty: existFilterQty,
        inputFocus,
      } as const as UseSearchReturnContextType),
    [
      query,
      updateCurrentQuery,
      appendHistoryItem,
      removeHistoryItem,
      currentQuery,
      history,
      clearHistory,
      correction,
      isUseCorrection,
      setSearchResult,
      products,
      total,
      zeroQueries,
      autoComplete.products,
      autoComplete.brands,
      autoComplete.taps,
      autoComplete.sts,
      autoComplete.categories,
      autoComplete.query,
      autoComplete.contents,
      isInitAC,
      setIsInitAC,
      isNotResultAC,
      isNotResults,
      facets,
      productsData,
      clearSearchResult,
      clearAutoComplete,
      page,
      setSearchFilter,
      updateSearch,
      updateFilterHandle,
      filter,
      isUpdate,
      selectedFacets,
      resetFilterHandle,
      isSubmitting,
      hideAutoComplete,
      showAutoComplete,
      isShowAC,
      isLoadingAutoComplete,
      sort,
      fullScreen,
      existFilterQty,
      inputFocus,
    ],
  )

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  )
}

const useSearch = (): UseSearchReturnType => {
  const searchContext = useContext(SearchContext)

  if (searchContext === null) {
    throw new Error("Search context have to be provided")
  }

  return {
    updateQueryText: searchContext.updateQueryText,
    query: searchContext.query,
    history: searchContext.history,
    appendToHistory: searchContext.appendToHistory,
    removeFromHistory: searchContext.removeFromHistory,
    clearHistory: searchContext.clearHistory,
    correction: searchContext.correction,
    currentText: searchContext.currentText,
    isUseCorrection: searchContext.isUseCorrection,
    zeroQueries: searchContext.zeroQueries,
    products: searchContext.products,
    total: searchContext.total,
    autoComplete: searchContext.autoComplete,
    isNotResults: searchContext.isNotResults,
    facets: searchContext.facets,
    page: searchContext.page,
    productsData: searchContext.productsData,
    clearSearchResult: searchContext.clearSearchResult,
    clearAutoComplete: searchContext.clearAutoComplete,
    updateSearch: searchContext.updateSearch,
    updateFilter: searchContext.updateFilter,
    isFilterUpdate: searchContext.isFilterUpdate,
    filter: searchContext.filter,
    selectedFacets: searchContext.selectedFacets,
    resetFilter: searchContext.resetFilter,
    setIsSubmitting: searchContext.setIsSubmitting,
    isSubmitting: searchContext.isSubmitting,
    hideAutoComplete: searchContext.hideAutoComplete,
    showAutoComplete: searchContext.showAutoComplete,
    isShowAutoComplete: searchContext.isShowAutoComplete,
    isLoadingAutoComplete: searchContext.isLoadingAutoComplete,
    sort: searchContext.sort,
    fullScreen: searchContext.fullScreen,
    inputRef: searchContext.inputRef,
    existFilterQty: searchContext.existFilterQty,
    inputFocus: searchContext.inputFocus,
  } as const
}

export { SearchContext, Provider, useSearch }
