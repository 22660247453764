import { ComponentType, FC, useCallback, useEffect } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { cx } from "@linaria/core";
import LazyHydrate from "react-lazy-hydration";
import { Portal } from "reakit";
import { Provider as HeaderProvider, useHeader } from "../../../hooks/header";
import { Provider as SearchProvider } from "../../../hooks/search/search";
import { AuthControls } from "./Auth/AuthControls";
import Logo from "./Logo/Logo";
import { NavigationStatic } from "./NavigationStatic";
import { ResponsiveMenuPropsType } from "./ResponsiveMenu/ResponsiveMenu";
import { LocationPopover, SelectLocation } from "./SelectLocation";
import { useLocationPopover } from "./SelectLocation/LocationPopover";
import { ButtonCatalogContainer, Controls, cssButtonSearchTrigger, cssHeaderCart, cssHeaderDefault, cssIsShowAutoComplete, cssScrollDown, cssScrollUp, RowBottom, RowTop, RowWrap, StyledHeader } from "./StyledHeader";
import { ButtonToCart } from "./ToCart";
import { ButtonToFavorite } from "./ToFavorite";
import { BannerHeader } from "@/components/Banners/Header";
import { SearchField } from "@/components/Search/Field";
import { StyledSearchPopup } from "@/components/Search/StyledSearch";
import { lazyHydrationConfig } from "@/hooks/lazyHydrate";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useScrollDirection } from "@/hooks/scrollDirection";
import { useWindowSize } from "@/hooks/windowSize";
import { ButtonToCompares } from "@/layouts/Default/Header/ToCompares";
import { setIsShowFullScreenSearch } from "@/store/reducers/searchSlice";
import { Container } from "@/styles/utils/StyledGrid";
import { cssIsActive, getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { cssButtonCatalogPopup, cssButtonMenuToggle } from "@/ui/Button/StyledButton";
import { ROUTES } from "@/utils/constants";
const CallInfo = dynamic(() => import("./CallInfo").then(mod => mod.CallInfo));
const ResponsiveMenu: ComponentType<ResponsiveMenuPropsType> = dynamic(() => import("./ResponsiveMenu/ResponsiveMenu").then(mod => mod.ResponsiveMenu));
const CatalogPopup = dynamic(() => import("@/components/Catalog/Popup").then(mod => mod.Popup));
const Notification = dynamic(() => import("@/components/Notification/Notification").then(mod => mod.Notification));
const Header: FC = () => {
  const catalogIsShowPopup = useAppSelector(({
    catalog
  }) => catalog.popup.isShow);
  const {
    autoComplete: {
      isShow: isShowAutoComplete
    },
    fullScreen: {
      isShow: isShowFullScreenSearch
    }
  } = useAppSelector(({
    search
  }) => search);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    isShowMiniCart,
    isShowMenu,
    notificationAuth,
    setNotificationAuth,
    catalogShowedToggle,
    menuHide,
    menuShow,
    setIsShowMiniCart
  } = useHeader();
  const scroll = useScrollDirection();
  const {
    width
  } = useWindowSize();
  const isLessLg = width !== undefined && width <= getBreakpointVal(breakpoints.lg);
  const isMoreLg = width !== undefined && width > getBreakpointVal(breakpoints.lg);
  const locationPopoverProps = useLocationPopover();
  const setIsShowMiniCartHandle = useCallback((value: boolean) => {
    if (!catalogIsShowPopup && isMoreLg) {
      setIsShowMiniCart(value);
    }
  }, [catalogIsShowPopup, setIsShowMiniCart, isMoreLg]);
  const fullScreenSearchShow = () => {
    dispatch(setIsShowFullScreenSearch(true));
  };
  useEffect(() => {
    switch (scroll) {
      case "up":
        {
          document.body.classList.add(cssScrollUp);
          document.body.classList.remove(cssScrollDown);
          break;
        }
      case "down":
        {
          document.body.classList.remove(cssScrollUp);
          document.body.classList.add(cssScrollDown);
          break;
        }
      case null:
        {
          document.body.classList.remove(cssScrollUp);
          document.body.classList.remove(cssScrollDown);
        }
    }
  }, [scroll]);
  const handleMenuShow = useCallback(() => {
    menuShow();
    locationPopoverProps.hidePopover();
    if (catalogIsShowPopup) {
      catalogShowedToggle(false);
    }
  }, [catalogIsShowPopup, menuShow, catalogShowedToggle, locationPopoverProps]);
  return <>
      <BannerHeader text={"Перейти на старую версию сайта"} href={"https://old.groster.me/"} />

      <StyledHeader data-isshow-catalog={catalogIsShowPopup} data-issshow-menu={isShowMenu} data-issshow-minicart={isShowMiniCart} className={cx(isShowAutoComplete && cssIsShowAutoComplete, cssHeaderDefault, router.pathname.includes(ROUTES.cart) && cssHeaderCart)}>
        <Container>
          <RowTop>
            <RowWrap>
              <SelectLocation />
              <NavigationStatic />
            </RowWrap>
            <AuthControls />
          </RowTop>

          <RowBottom>
            {isLessLg && <>
                <Button variant={"box"} icon={isShowMenu ? "X" : "Menu"} className={cssButtonMenuToggle} onClick={isShowMenu ? menuHide : handleMenuShow} />
                <LocationPopover {...locationPopoverProps} />
              </>}
            <Logo />

            <ButtonCatalogContainer>
              <Button as={"a"} href={ROUTES.catalog} variant={"filled"} icon={catalogIsShowPopup ? "X" : "ViewBoxes"} onClick={e => {
              e.preventDefault();
              catalogShowedToggle();
            }} className={cx(cssButtonCatalogPopup, catalogIsShowPopup && cssIsActive)}>
                Каталог
              </Button>
            </ButtonCatalogContainer>

            {isMoreLg && <LazyHydrate whenVisible={lazyHydrationConfig}>
                <SearchField />
              </LazyHydrate>}

            <Controls>
              <LazyHydrate whenVisible={lazyHydrationConfig}>
                <CallInfo />
              </LazyHydrate>

              <ButtonToCompares />

              <ButtonToFavorite />

              {isLessLg && <Button variant={"box"} icon={"Search"} type={"submit"} className={cx(cssButtonSearchTrigger)} onClick={fullScreenSearchShow} seoText={"Поиск"} />}

              {isMoreLg && <ButtonToCart isShowMiniCart={isShowMiniCart} setIsShowMiniCart={setIsShowMiniCartHandle} />}
            </Controls>

            {isLessLg && <LazyHydrate whenVisible={lazyHydrationConfig}>
                <ResponsiveMenu isShow={isShowMenu} />
              </LazyHydrate>}
          </RowBottom>
        </Container>

        <LazyHydrate whenVisible={lazyHydrationConfig}>
          <CatalogPopup />
        </LazyHydrate>

        {notificationAuth !== null && <LazyHydrate whenIdle>
            <Notification isOpen={true} notification={notificationAuth} setNotification={setNotificationAuth} />
          </LazyHydrate>}

        {isShowFullScreenSearch && <LazyHydrate whenIdle>
            <Portal>
              <StyledSearchPopup>
                <SearchField isResponsiveMode isFocusInit />
              </StyledSearchPopup>
            </Portal>
          </LazyHydrate>}
      </StyledHeader>
    </>;
};
Header.displayName = "Header";
const HeaderWithProviders: FC = () => {
  return <SearchProvider>
      <HeaderProvider>
        <Header />
      </HeaderProvider>
    </SearchProvider>;
};
export { HeaderWithProviders as Header };