import {
  V1FavoritesAddCreatePayload,
  V1FavoritesDeleteDeletePayload,
} from "../../contracts"
import { deleteFetch, get, post } from "@/service/fetcher"

export const fetchFavorites = (): Promise<{ products: string[] } | null> =>
  get<{ products: string[] } | null>("/favorites", true)

export const fetchAddToFavorites = (
  product: V1FavoritesAddCreatePayload,
): Promise<null> =>
  post<null, V1FavoritesAddCreatePayload>("/favorites/add", product, true)

export const fetchRemoveToFavorites = (
  product: V1FavoritesDeleteDeletePayload,
): Promise<null> =>
  deleteFetch<null, V1FavoritesDeleteDeletePayload>(
    "/favorites/delete",
    product,
    true,
  )

export const fetchRemoveAllFavorites = (): Promise<null> =>
  deleteFetch("/favorites/delete-all", null, true)
