import { FC } from "react"
import { DigiProductAutoCompleteType } from "../../../../../api/digineticaAPI"
import { IMAGE_CONFIG } from "../../../../Products/constants"
import { Price, Title } from "../../../../Products/parts"
import { CodeArticleInfo } from "../../../../Products/parts/CodeArticleInfo"
import { EntityImage } from "../../../../ui/EntityImage/EntityImage"
import { StyledProduct } from "./StyledProduct"

export const Product: FC<{
  product: DigiProductAutoCompleteType
}> = ({ product }) => {
  const article = (product.attributes["vendorcode"] || [])[0]
  const code = (product.attributes["code"] || [])[0]

  return (
    <StyledProduct>
      <EntityImage
        imagePath={product.image_url}
        quality={IMAGE_CONFIG.small.quality}
        width={IMAGE_CONFIG.small.size.width}
        height={IMAGE_CONFIG.small.size.height}
      />
      <Title name={product.name} path={product.link_url} />
      <Price
        price={+product.price}
        variant={"unit"}
        unitMeasure={"шт"}
        withBg
      />
      <CodeArticleInfo code={code} article={article} />
    </StyledProduct>
  )
}

Product.displayName = "SearchProduct"
