import React, { FC, memo } from "react";
import { Button } from "../../../ui/Button/Button";
import { Icon } from "../../../ui/Icon";
import { Typography } from "../../../ui/Typography/Typography";
import { cssWithIcon, StyledResultItem, StyledResultItemInner } from "../StyledAutoComplete";
import { HistoryItemPropsType } from "./types";
const HistoryItem: FC<HistoryItemPropsType> = memo(({
  selectItem,
  removeFromHistory,
  item,
  currentText,
  icon = "RefreshTime"
}) => {
  const text = !!currentText ? item.query.replace(currentText, `<b>${currentText}</b>`) : item.query;
  return <StyledResultItem className={cssWithIcon}>
        <StyledResultItemInner onClick={e => {
      e.preventDefault();
      selectItem(item.query);
    }}>
          <Icon NameIcon={icon} size={"medium"} />
          <Typography variant={"p14"}>
            <span dangerouslySetInnerHTML={{
          __html: text
        }} />
          </Typography>
        </StyledResultItemInner>
        <Button variant={"box"} icon={"X"} onClick={e => {
      e.preventDefault();
      removeFromHistory({
        query: item.query
      });
    }} />
      </StyledResultItem>;
});
HistoryItem.displayName = "HistoryItem";
export default HistoryItem;