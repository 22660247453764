import { FC, memo } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { cx } from "@linaria/core";
import LazyHydrate from "react-lazy-hydration";
import PushCounter from "@/components/PushCounter/PushCounter";
import { useCart } from "@/hooks/cart/cart";
import { Wrapper } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
import { cssButtonToCart } from "@/ui/Button/StyledButton";
import { ROUTES } from "@/utils/constants";
const MiniCart = dynamic(() => import("@/components/Cart/MiniCart").then(m => m.MiniCart));
export const ButtonToCart: FC<{
  isShowMiniCart?: boolean;
  setIsShowMiniCart?: (val: boolean) => void;
  isShowedBGbutton?: boolean;
}> = memo(({
  isShowMiniCart,
  setIsShowMiniCart,
  isShowedBGbutton = true
}) => {
  const {
    cartCount
  } = useCart();
  const {
    pathname
  } = useRouter();
  const onMouseEnterhandle = () => {
    if ([ROUTES.cart, ROUTES.checkout].includes(pathname)) {
      return;
    }
    !!setIsShowMiniCart && setIsShowMiniCart(true);
  };
  const onMouseLeaveHandle = () => {
    !!setIsShowMiniCart && setIsShowMiniCart(false);
  };
  return <>
      <Wrapper onMouseEnter={onMouseEnterhandle} onMouseLeave={onMouseLeaveHandle}>
        <Button as={"a"} variant={"box"} icon={"ShoppingCart"} className={cx(isShowedBGbutton && cssButtonToCart)} href={ROUTES.cart} aria-label={"Перейти в корзину"} seoText={"Перейти в корзину"}>
          <PushCounter qty={cartCount} />
        </Button>
        {isShowMiniCart && <LazyHydrate on="click">
            <MiniCart />
          </LazyHydrate>}
      </Wrapper>
    </>;
});
ButtonToCart.displayName = "ButtonToCart";