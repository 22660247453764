import type { FC } from "react";
import dynamic from "next/dynamic";
import { cx } from "@linaria/core";
import LazyHydrate from "react-lazy-hydration";
import { Header } from "./Header";
import { useCart } from "@/hooks/cart/cart";
import { useCompares } from "@/hooks/compares";
import { useFavorites } from "@/hooks/favorites";
import { lazyHydrationConfig } from "@/hooks/lazyHydrate";
import { useAppSelector } from "@/hooks/redux";
import { cssLayoutWithAutoComplete, PageWrapper } from "@/styles/utils/Utils";
const OfferToLogInPopup = dynamic(() => import("@/components/Products/parts/OfferToLogInPopup").then(({
  OfferToLogInPopup
}) => OfferToLogInPopup), {
  ssr: false
});
const CartBindPopup = dynamic(() => import("@/components/Cart/Bind").then(({
  CartBindPopup
}) => CartBindPopup), {
  ssr: false
});
const OnlyAuthModal = dynamic(() => import("@/layouts/Default/Header/Auth/OnlyAuthModal").then(({
  OnlyAuthModal
}) => OnlyAuthModal), {
  ssr: false
});
const AuthModal = dynamic(() => import("@/layouts/Default/Header/Auth/AuthModal").then(({
  AuthModal
}) => AuthModal), {
  ssr: false
});
const WatchedRecently = dynamic(() => import("@/components/Products/WatchedRecently").then(mod => mod.WatchedRecently));
const FooterBar = dynamic(() => import("@/components/FooterBar").then(mod => mod.FooterBar));
const AllowCookie = dynamic(() => import("@/components/AllowCookie").then(mod => mod.AllowCookie));
const Footer = dynamic(() => import("./Footer/Footer").then(mod => mod.Footer));
export const DefaultLayout: FC = ({
  children,
  ...rest
}) => {
  const isShowSearchAutoComplete = useAppSelector(({
    search
  }) => search.autoComplete.isShow);
  const {
    isShowToLogin: isShowToLoginFavorites,
    hideToLogin: hideToLoginFavorites
  } = useFavorites();
  const {
    isShowToLogin: isShowToLoginCompares,
    hideToLogin: hideToLoginCompares
  } = useCompares();
  const {
    isShowToLogin: isShowToLoginCart,
    hideToLogin: hideToLoginCart
  } = useCart();
  return <PageWrapper {...rest} id="pageRoot" className={cx(isShowSearchAutoComplete && cssLayoutWithAutoComplete)}>
      <Header />

      {children}

      <LazyHydrate whenIdle>
        <AllowCookie />
      </LazyHydrate>

      <CartBindPopup />

      <OfferToLogInPopup isShow={isShowToLoginFavorites} title="Товар добавлен в Избранное" text="Войдите в профиль, чтобы сохранить список. Не сохраненный список будет очищен через 7 дней." onHide={hideToLoginFavorites} />

      <OfferToLogInPopup isShow={isShowToLoginCompares} title="Товар добавлен к сравнению" text="Войдите в профиль, чтобы сохранить сравнения. Не сохраненные сравнения будут очищены через 7 дней." onHide={hideToLoginCompares} />

      <OfferToLogInPopup isShow={isShowToLoginCart} title="Товар добавлен в корзину" text="Войдите в профиль, чтобы сохранить корзину. Не сохраненная корзина будет очищена через 7 дней." onHide={hideToLoginCart} />

      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <WatchedRecently />
      </LazyHydrate>

      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <Footer />
      </LazyHydrate>

      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <FooterBar />
      </LazyHydrate>

      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <OnlyAuthModal />
      </LazyHydrate>

      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <AuthModal />
      </LazyHydrate>
    </PageWrapper>;
};