import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  AddressType,
  OrderList,
  OrderType,
  ParamsResponse,
  ProductDetailListType,
  ResponseCustomerDataList,
} from "../../../contracts/contracts"
import { ProductType } from "@/components/Products/types"
import { setUser, User } from "@/hooks/auth"
import { TogglePageMethod } from "@/hooks/filterCatalog/constants"
import { QueryFiltersHistoryType } from "@/hooks/filterHistory/types"
import { OrderStateNumberType } from "@/hooks/order/types"
import {
  IAvailableParam,
  IAvailableParams,
  IAvailableParamsKeys,
  IFilterParamsChilds,
  IFilterParamsParents,
  LastOrderReturnType,
  PayerListItemType,
} from "@/types"
import {
  getAvailableParamsTree,
  getFilterParamsTree,
  matchKeys,
  sortProductsFromApi,
} from "@/utils/helpers"

export const INITIAL_PAGE = 1
export const INITIAL_PER_PAGE = 10

export const MAX_COUNT_PRODUCTS = 31

const initialState = {
  favorites: {
    keys: null as string[] | null,
    products: null as ProductDetailListType | null,
    toggleProgress: [] as string[] | [],
  },
  addresses: null as AddressType[] | null,
  payers: null as PayerListItemType[] | null,
  user: null as Required<User> | null,
  history: {
    orders: {
      total: 0 as number,
      items: null as Record<string, OrderType> | null,
    },
    products: {
      total: 0 as number,
      items: null as Record<string, ProductType> | null,
    },
    filter: {
      availableParams: null as IAvailableParams | null,
      params: {} as IFilterParamsParents & IFilterParamsChilds,
      checkedParamsKeysTotal: [] as string[],
      state: null as (OrderStateNumberType | -1) | null,
      category: null as string | null,
    },
    query: null as QueryFiltersHistoryType | null,
    page: INITIAL_PAGE as number,
    itemsPerPage: INITIAL_PER_PAGE as number,
    togglePageMethod: TogglePageMethod.SWITCH as TogglePageMethod,
    watchedRecentProducts: {
      keys: null as string[] | null,
      products: null as ProductDetailListType | null,
      exclude: [] as string[],
    },
  },
  priceList: {
    indeterminate: [] as string[] | [],
    selected: [] as string[] | [],
  },
  isAuth: false as boolean | false,
  isInit: false as boolean | false,
  lastOrder: null as LastOrderReturnType | null,
  customer: null as ResponseCustomerDataList | null,
}
export const accountSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    addToFavorites(state, { payload }: PayloadAction<string[]>) {
      state.favorites.keys = matchKeys({
        stateKeys: state.favorites.keys,
        newKeys: payload,
      })
    },
    removeFromFavorites(state, action: PayloadAction<string>) {
      if (state.favorites.keys === null) {
        return
      }
      const filter = state.favorites.keys.filter(
        (item: string) => item !== action.payload,
      )
      state.favorites.keys = !!filter.length ? filter : null
      state.favorites.products = state.favorites.products ? state.favorites.products.filter(product => {
        product.uuid !== action.payload
      }) : state.favorites.products
    },
    clearFavorites(state) {
      state.favorites.keys = []
      state.favorites.products = []
      state.favorites.toggleProgress = []
    },
    setFavoritesProducts(
      state,
      { payload }: PayloadAction<ProductDetailListType>,
    ) {
      state.favorites.products = sortProductsFromApi(
        payload,
        state.favorites.keys,
      )
    },
    setUserProfile(state, action: PayloadAction<User | null>) {
      if (action.payload !== null) {
        state.user = {
          email: action.payload.email || null,
          accessToken: action.payload.accessToken || null,
          phone: action.payload.phone || null,
          cart: action.payload.cart || null,
          refreshToken: action.payload.refreshToken || null,
          fio: action.payload.fio || null,
          isAdmin: !!action.payload.isAdmin,
        }
      } else {
        state.user = action.payload
      }

      setUser(action.payload)
    },
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload
    },
    setIsInit(state, action: PayloadAction<boolean>) {
      state.isInit = action.payload
    },
    toggleFavoritesProgress(
      state,
      action: PayloadAction<{ isFetching: boolean; product: string }>,
    ) {
      state.favorites.toggleProgress = action.payload.isFetching
        ? [...state.favorites.toggleProgress, action.payload.product]
        : state.favorites.toggleProgress.filter(
          (id) => id !== action.payload.product,
        )
    },
    setAddresses(state, action: PayloadAction<AddressType[] | null>) {
      state.addresses = action.payload
    },
    setPayers(state, action: PayloadAction<PayerListItemType[] | null>) {
      state.payers = action.payload
    },
    setHistoryFilterParams(state, { payload }: PayloadAction<ParamsResponse>) {
      state.history.filter.params = getFilterParamsTree(payload)
    },
    setHistoryFilterAvailableParams(
      state,
      action: PayloadAction<IAvailableParamsKeys | null>,
    ) {
      if (action.payload !== null) {
        state.history.filter.availableParams = getAvailableParamsTree(
          state.history.filter.params,
          action.payload,
        ).tree
      } else {
        state.history.filter.availableParams = action.payload
      }
    },
    updateCheckedParams(
      state,
      action: PayloadAction<{
        keyFilter: string[]
        checked: boolean
      }>,
    ) {
      if (!!state.history.filter.availableParams) {
        const { keyFilter, checked } = action.payload
        keyFilter.map((key) => {
          if (!!state.history.filter.availableParams) {
            const parent: IAvailableParam =
              state.history.filter.availableParams[
              state.history.filter.params[key].parentUuid || ""
              ]

            if (!!parent.params[key]) {
              parent.params[key].checked = checked
            }
            if (checked) {
              parent.checkedKeys = [...parent.checkedKeys, key].reduce(
                (uniq: string[], item) => {
                  return uniq.includes(item) ? uniq : [...uniq, item]
                },
                [],
              )
              state.history.filter.checkedParamsKeysTotal = [
                ...state.history.filter.checkedParamsKeysTotal,
                key,
              ].reduce((uniq: string[], item) => {
                return uniq.includes(item) ? uniq : [...uniq, item]
              }, [])
            } else {
              parent.checkedKeys = parent.checkedKeys.filter((k) => k !== key)
              state.history.filter.checkedParamsKeysTotal =
                state.history.filter.checkedParamsKeysTotal.filter(
                  (k) => k !== key,
                )
            }
          }
        })
      }
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.history.page = action.payload
    },
    setItemsPerPage(state, action: PayloadAction<number | null>) {
      state.history.itemsPerPage = action.payload || INITIAL_PER_PAGE
    },
    setTotalOrders(state, action: PayloadAction<number>) {
      state.history.orders.total = action.payload
    },
    setTogglePageMethod(state, action: PayloadAction<TogglePageMethod>) {
      state.history.togglePageMethod = action.payload
    },
    setWatchedRecentProductsIds(state, { payload }: PayloadAction<string[]>) {
      state.history.watchedRecentProducts.keys = matchKeys({
        stateKeys: state.history.watchedRecentProducts.keys,
        newKeys: payload,
        limit: MAX_COUNT_PRODUCTS,
      })
    },
    setWatchedRecentProducts(
      state,
      action: PayloadAction<ProductDetailListType>,
    ) {
      state.history.watchedRecentProducts.products = sortProductsFromApi(
        action.payload,
        state.history.watchedRecentProducts.keys,
      )
    },
    addExcludeWatchedRecentProduct(
      state,
      { payload }: PayloadAction<string[]>,
    ) {
      state.history.watchedRecentProducts.exclude = [
        ...state.history.watchedRecentProducts.exclude,
        ...payload,
      ]
    },
    removeExcludeWatchedRecentProduct(
      state,
      { payload }: PayloadAction<string[]>,
    ) {
      state.history.watchedRecentProducts.exclude =
        state.history.watchedRecentProducts.exclude.filter(
          (uuid) => !payload.includes(uuid),
        )
    },
    setFilterState(
      state,
      action: PayloadAction<(OrderStateNumberType | -1) | null>,
    ) {
      state.history.filter.state = action.payload
    },
    setFilterCategory(state, action: PayloadAction<string | null>) {
      state.history.filter.category = action.payload
    },
    setTotalProducts(state, action: PayloadAction<number>) {
      state.history.products.total = action.payload
    },
    setHistoryProducts(
      state,
      action: PayloadAction<{
        products: ProductType[] | null
        queueKeys: string[]
      }>,
    ) {
      // при выборе Категории в фильтрах
      // товары из этой категории должны выводиться первыми
      // получили нужны порядок ключей и хаотичный порядок товаров
      // сопоставляем порядок и данные товаров
      if (action.payload.products !== null) {
        const items = {}
        let products = [...action.payload.products]
        for (const uuid of action.payload.queueKeys) {
          const foundProduct = products.find((p) => p.uuid === uuid)
          if (!foundProduct) {
            continue
          }
          items[uuid] = { ...foundProduct }
          products = products.filter((p) => p.uuid !== uuid)
        }

        if (products.length > 0) {
          for (const prod of products) {
            if (!prod.uuid) {
              continue
            }
            items[prod.uuid] = { ...prod }
          }
        }

        state.history.products.items = items
      } else {
        state.history.products.items = null
      }
    },
    setOrders: (state, action: PayloadAction<OrderList[]>) => {
      if (action.payload !== null) {
        const orders = {}
        for (const order of action.payload) {
          if (order.uid == undefined) {
            continue
          }
          orders[order.uid] = { ...order }
        }
        state.history.orders.items = orders
      } else {
        state.history.orders.items = action.payload
      }
    },
    updateOrderState: (
      state,
      action: PayloadAction<{ uid: string; state: OrderStateNumberType }>,
    ) => {
      if (state.history.orders.items !== null) {
        const order = state.history.orders.items[action.payload.uid]
        if (order !== undefined) {
          state.history.orders.items[action.payload.uid].state =
            action.payload.state
        }
      }
    },
    setCategoriesPriceListSelected: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.priceList.selected = action.payload
    },
    setLastOrder: (
      state,
      action: PayloadAction<null | LastOrderReturnType>,
    ) => {
      state.lastOrder =
        action.payload !== null ? { ...action.payload, replacement: 3 } : null
    },
    setCustomer: (
      state,
      action: PayloadAction<null | ResponseCustomerDataList>,
    ) => {
      state.customer = action.payload
    },
    setQuery: (
      state,
      { payload }: PayloadAction<QueryFiltersHistoryType | null>,
    ) => {
      state.history.query = payload
    },
  },
})

export const {
  setPayers,
  setIsInit,
  setUserProfile,
  setIsAuth,
  clearFavorites,
  addToFavorites,
  setFavoritesProducts,
  setHistoryProducts,
  setTotalProducts,
  removeFromFavorites,
  setTotalOrders,
  setOrders,
  setLastOrder,
  toggleFavoritesProgress,
  setCategoriesPriceListSelected,
  updateOrderState,
  setHistoryFilterParams,
  setHistoryFilterAvailableParams,
  updateCheckedParams,
  setCurrentPage,
  setWatchedRecentProductsIds,
  setWatchedRecentProducts,
  addExcludeWatchedRecentProduct,
  removeExcludeWatchedRecentProduct,
  setFilterState,
  setFilterCategory,
  setItemsPerPage,
  setCustomer,
  setQuery,
} = accountSlice.actions
