import { type FC } from "react"
import { Notification } from "./Notification"
import { StyledNotifications } from "./StyledNotifications"
import { useAppSelector } from "@/hooks/redux"

export const Notifications: FC = () => {
  const notifications = useAppSelector(
    (state) => state.notifications.notifications,
  )

  return !!notifications.length ? (
    <StyledNotifications>
      {notifications.map(({ index, kind }) => (
        <Notification key={index} index={index} kind={kind} />
      ))}
    </StyledNotifications>
  ) : null
}
