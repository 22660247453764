import React, { FC, memo, MutableRefObject, useCallback, useEffect, useState } from "react";
import { cx } from "@linaria/core";
import { cssTagRectification, StyledTags, StyledTagsContainer } from "../../Tags/StyledTags";
import { Category } from "./Category";
import { History } from "./History";
import { StyledProducts } from "./Products/Product/StyledProduct";
import { Products } from "./Products/Products";
import { cssCategories, cssSts, cssTaps, StyledAutoComplete, StyledResult, StyledResultItem, StyledResultItemInner, StyledResultList, StyledResultListArea, StyledResultTitle } from "./StyledAutoComplete";
import { useAppSelector } from "@/hooks/redux";
import { useSearch } from "@/hooks/search/search";
import { useWindowSize } from "@/hooks/windowSize";
import { cssIsActive, getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { Icon } from "@/ui/Icon";
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader";
import { Typography } from "@/ui/Typography/Typography";
export type AutoCompletePropsType = {
  fieldRef?: MutableRefObject<HTMLDivElement | null>;
  selectQuery?: (query: string) => void;
  showAllResults?: () => void;
};
const AutoComplete: FC<AutoCompletePropsType> = memo(({
  selectQuery,
  showAllResults,
  ...props
}) => {
  const {
    history,
    removeFromHistory,
    clearHistory,
    currentText,
    autoComplete: {
      products,
      taps,
      sts,
      categories,
      query,
      isNotResult
    },
    updateSearch,
    hideAutoComplete,
    isLoadingAutoComplete,
    isShowAutoComplete
  } = useSearch();
  const {
    width
  } = useWindowSize();
  const isMoreMd = width !== undefined && width > getBreakpointVal(breakpoints.md);
  const selectItem = useCallback((query: string) => {
    if (selectQuery) {
      selectQuery(query);
    }
  }, [selectQuery]);
  const queryIsExist = query !== null && query.length > 0;
  // показываем товары только
  // если ПК версия
  // или нет подсказок и совпадений от дигинетики
  const isShowProducts = isMoreMd || !sts.length && !taps.length;
  const updateCategorySearch = useCallback(({
    title
  }: {
    cId: string;
    title: string;
  }) => {
    updateSearch({
      query: title,
      withReset: true
    });
  }, [updateSearch]);
  return <StyledAutoComplete {...props} className={cx(isShowAutoComplete && cssIsActive)}>
        {isLoadingAutoComplete && <BaseLoader />}

        <StyledResultListArea>
          {taps.length > 0 && <StyledResult className={cssTaps}>
              <StyledTagsContainer>
                <StyledTags>
                  {taps.map((t, i) => <Button key={i} className={cssTagRectification} onClick={() => {
              updateSearch({
                query: t.relatedSearch,
                withReset: true
              });
            }}>
                      {t.relatedSearch}
                    </Button>)}
                </StyledTags>
              </StyledTagsContainer>
            </StyledResult>}

          <History removeFromHistory={removeFromHistory} clearHistory={clearHistory} currentText={isNotResult ? "" : currentText} history={history} selectItem={selectItem} taps={taps} />

          {sts.length > 0 && <StyledResult className={cssSts}>
              <StyledResultTitle>Часто ищут</StyledResultTitle>
              <StyledResultList>
                {sts.filter((_, i) => i < 3).map((item, i) => <StyledResultItem key={i}>
                      <StyledResultItemInner onClick={() => {
              selectItem(item.st);
            }}>
                        <Icon NameIcon={"Search"} size={"mediumM"} />
                        <Typography variant={"p14"}>{item.st}</Typography>
                      </StyledResultItemInner>
                    </StyledResultItem>)}
              </StyledResultList>
            </StyledResult>}

          {!!categories.length && queryIsExist && <StyledResult className={cssCategories}>
              <StyledResultTitle>Найдено в категориях</StyledResultTitle>
              <StyledProducts>
                {categories.map(c => <Category category={c} key={c.id} updateCategorySearch={updateCategorySearch} />)}
              </StyledProducts>
            </StyledResult>}
        </StyledResultListArea>

        {isShowProducts && <Products products={products} hideAutoComplete={hideAutoComplete}>
            {queryIsExist && <Button variant={"translucent"} size={"small"} onClick={() => {
        if (showAllResults) {
          showAllResults();
        }
      }}>
                Смотреть все результаты
              </Button>}
          </Products>}
      </StyledAutoComplete>;
});
const AutoCompleteWrapper: FC<AutoCompletePropsType> = props => {
  const defaultIsShow = useAppSelector(state => state.search.autoComplete.isShow);
  const [isInit, setIsInit] = useState(defaultIsShow);
  useEffect(() => {
    if (defaultIsShow) {
      setIsInit(defaultIsShow);
    }
  }, [defaultIsShow]);
  return <>{isInit && <AutoComplete {...props} />}</>;
};
AutoComplete.displayName = "AutoComplete";
export { AutoCompleteWrapper as AutoComplete };