import { FC, memo, SVGProps } from "react";
import { css, CSSProperties } from "@linaria/core";
import { IconInner, matching } from "./Icon";
import { colors, sizeSVG } from "@/styles/utils/vars";
export type IconNameType = keyof typeof matching;
export type sizeSVGNamesType = keyof typeof sizeSVG;
export interface IconPropsType {
  NameIcon: IconNameType;
  fill?: string;
  size?: sizeSVGNamesType;
}
export type GetSizeSVGType = (size: typeof sizeSVG[keyof typeof sizeSVG]) => string | number | CSSProperties;
export const getSizeSVG: GetSizeSVGType = size => {
  return {
    width: size,
    height: size,
    minWidth: size,
    minHeight: size
  };
};
export const cssIcon = "cssIcon_cc7xf1o";
export const cssIconShoppingCart = "cssIconShoppingCart_c1bwwamh";
export const Icon: FC<IconPropsType & SVGProps<SVGSVGElement>> = memo(({
  NameIcon,
  fill,
  size = "default",
  className,
  ...props
}) => {
  const classNameCalc = `${cssIcon} ${className ? className : ""} ${size ? `${size}` : ""}`;
  const styles: CSSProperties | undefined = fill ? {
    fill: fill
  } : undefined;
  return <IconInner {...props} NameIcon={NameIcon} className={classNameCalc} style={styles} />;
});
Icon.displayName = "Icon";

require("./index.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");