import { FC, memo } from "react";
import { cssIsActive } from "../../../styles/utils/Utils";
import { cssListVertical, StyledList, StyledListItem } from "../../List/StyledList";
import { Button } from "../Button/Button";
import { Icon } from "../Icon";
import { Popover } from "../Popover/Popover";
import { NavigationTitle } from "./NavigationTitle";
import { StyledNav } from "./StyledNavigation";
import { NavigationItemPropsType } from "./types";
const NavigationItem: FC<NavigationItemPropsType> = memo(({
  title,
  path,
  subItems = [],
  variant,
  activePath,
  as = "li",
  isScroll = true,
  isLink = true,
  isStylingIconDisclosure = true,
  currentPath,
  qty
}) => {
  if (!title.length && !path.length) {
    return <></>;
  }
  const isActiveClass = activePath === path ? cssIsActive : activePath?.split("/").filter((p, i) => i < 3).join("/").replace("//", "/") === path ? cssIsActive : "";
  const isLinkProp = isLink && path !== currentPath;
  return <StyledListItem as={as} className={isActiveClass}>
        {!subItems.length ? <NavigationTitle title={title} path={path} variant={variant} isScroll={isScroll} isLink={isLinkProp} qty={qty} /> : <Popover placement={"bottom-start"} withHover isStylingIconDisclosure={isStylingIconDisclosure} offset={[0, 0]} aria-label={title || "popoverNav"} disclosure={<Button>
                <NavigationTitle title={title} path={path} variant={variant} isScroll={isScroll} isLink={isLinkProp} qty={qty} />
                <Icon NameIcon={"AngleBottom"} />
              </Button>}>
            <StyledNav>
              <StyledList className={cssListVertical}>
                {subItems.map((si, i) => <NavigationItem key={i} title={si.title} path={si.path} isScroll={isScroll} variant={variant} qty={si.qty} />)}
              </StyledList>
            </StyledNav>
          </Popover>}
      </StyledListItem>;
});
NavigationItem.displayName = "NavigationItem";
export { NavigationItem };