import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useRouter } from "next/router"
import { Category } from "../../contracts/contracts"
import { NotificationType } from "../components/Notification/Notification"
import { scrollBodyDisable, scrollBodyEnable } from "../utils/helpers"
import { useAuth } from "./auth"
import { useAppDispatch, useAppSelector } from "./redux"
import { setPopupIsShow } from "store/reducers/catalogSlice"

type UseHeaderReturnType = {
  isShowPopup: boolean
  isShowMenu: boolean
  isShowMiniCart: boolean
  catalogShowedToggle: (on?: boolean) => void
  menuHide: () => void
  menuShow: () => void
  businessAreas: Category[] | null
  notificationAuth: NotificationType | null
  setIsShowMiniCart: (value: boolean) => void
  setNotificationAuth: (notification: NotificationType | null) => void
}

type HeaderContextPropsType = UseHeaderReturnType

const HeaderContext = createContext<null | HeaderContextPropsType>(null)

export function Provider({
  children,
}: {
  children?: ReactNode
  host?: string
}): JSX.Element {
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isShowMiniCart, setIsShowMiniCart] = useState(false)

  const {
    notification: notificationAuth,
    setNotification: setNotificationAuth,
  } = useAuth()

  const { businessAreas } = useAppSelector(({ categories }) => categories)
  const {
    popup: { isShow: catalogPopupIsShow },
  } = useAppSelector(({ catalog }) => catalog)
  const dispatch = useAppDispatch()

  const router = useRouter()

  const menuHide = () => {
    setIsShowMenu(false)
    scrollBodyEnable()
  }

  const menuShow = () => {
    scrollBodyDisable()
    setIsShowMenu(true)
  }

  const catalogShowedToggle = (on?: boolean) => {
    if (on === undefined) {
      dispatch(setPopupIsShow(!catalogPopupIsShow))
    } else {
      dispatch(setPopupIsShow(on))
    }
  }

  useEffect(() => {
    const cleanShowedDialogs = () => {
      menuHide()
      setIsShowMiniCart(false)
    }

    const routeChangeHandle = () => {
      dispatch(setPopupIsShow(false))
      cleanShowedDialogs()
    }

    router.events.on("routeChangeComplete", routeChangeHandle)

    return () => {
      router.events.off("routeChangeComplete", routeChangeHandle)
    }
  }, [router.events, dispatch])

  const contextValue = useMemo(
    () => ({
      businessAreas: businessAreas,
      isShowMenu: isShowMenu,
      isShowMiniCart: isShowMiniCart,
      isShowPopup: catalogPopupIsShow,
      catalogShowedToggle: catalogShowedToggle,
      notificationAuth: notificationAuth,
      setIsShowMiniCart: setIsShowMiniCart,
      menuHide: menuHide,
      menuShow: menuShow,
      setNotificationAuth,
    }),
    [
      businessAreas,
      isShowMenu,
      isShowMiniCart,
      catalogPopupIsShow,
      notificationAuth,
      setNotificationAuth,
    ],
  )

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeader = (): UseHeaderReturnType & HeaderContextPropsType => {
  const headerContext = useContext(HeaderContext)

  if (headerContext === null) {
    throw new Error("Header context have to be provided")
  }

  return {
    businessAreas: headerContext.businessAreas,
    isShowMenu: headerContext.isShowMenu,
    isShowMiniCart: headerContext.isShowMiniCart,
    isShowPopup: headerContext.isShowPopup,
    catalogShowedToggle: headerContext.catalogShowedToggle,
    notificationAuth: headerContext.notificationAuth,
    setIsShowMiniCart: headerContext.setIsShowMiniCart,
    menuHide: headerContext.menuHide,
    menuShow: headerContext.menuShow,
    setNotificationAuth: headerContext.setNotificationAuth,
  } as const
}
