import { cloneElement, createContext, type FC, type FunctionComponentElement, memo, type ReactNode, useCallback, useEffect, useRef, useState, TouchEvent } from "react";
import dynamic from "next/dynamic";
import { cx } from "@linaria/core";
import { DialogDisclosure, DisclosureProps, useDialogState } from "reakit";
import { Button } from "../Button/Button";
import { Typography } from "../Typography/Typography";
import { cssButtonModalClose, cssDialog, cssDialogBackdrop, Header, Heading, StyledDialogContent, StyledHeaderDialogContent, StyledOverlay } from "./StyledModal";
import { useWindowSize } from "@/hooks/windowSize";
import { cssIsSticky } from "@/layouts/Default/Header/StyledHeader";
import { getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { CloseModeType } from "@/types";
import { scrollBodyDisable, scrollBodyEnable } from "@/utils/helpers";
const DialogBackdrop = dynamic(() => import("reakit").then(reakit => reakit.DialogBackdrop), {
  ssr: false
});
const Dialog = dynamic(() => import("reakit").then(reakit => reakit.Dialog), {
  ssr: false
});
export type ModalDefaultPropsType = {
  variant?: "rounded-0" | "full" | "rounded-100" | "rounded-50" | "rounded-70";
  title?: string;
  disclosure?: FunctionComponentElement<unknown>;
  onClose?: () => void;
  isShowModal?: boolean;
  closeMode?: CloseModeType;
  hideOnClickOutside?: boolean;
  isModal?: boolean;
  children?: ReactNode;
  classNameBackdrop?: string;
};
export const ModalContext = createContext<null | {
  hide: () => void;
  baseId: string;
}>(null);
export const Modal: FC<ModalDefaultPropsType> = memo(({
  title,
  variant = "rounded-0",
  disclosure,
  children,
  onClose,
  isShowModal,
  closeMode = "hide",
  hideOnClickOutside = false,
  isModal = true,
  classNameBackdrop
}) => {
  const dialogState = useDialogState({
    animated: 1000,
    modal: isModal
  });
  const element = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const {
    width,
    height: deviceHeight
  } = useWindowSize();
  const previousVisibleRef = useRef(dialogState.visible);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const isResponsive = width !== undefined && width <= getBreakpointVal(breakpoints.md);
  const onScrollhandle = useCallback(() => {
    if (dialogState.animating) {
      return;
    }
    const offsetTop = bodyRef.current?.getBoundingClientRect().top || 0;
    setIsSticky(offsetTop < 10);
  }, [dialogState.animating]);
  useEffect(() => {
    if (previousVisibleRef.current !== dialogState.visible) {
      if (!dialogState.visible) {
        scrollBodyEnable();
        onClose && onClose();
      } else {
        scrollBodyDisable(element.current || undefined);
      }
    }
    previousVisibleRef.current = dialogState.visible;
  }, [dialogState.baseId, dialogState.visible]);
  useEffect(() => {
    if (isShowModal !== undefined) {
      if (isShowModal) {
        dialogState.show();
      } else {
        dialogState.hide();
      }
    }
  }, [isShowModal]);
  useEffect(() => {
    return () => {
      scrollBodyEnable();
    };
  }, []);
  const dialogContentDraggingHandle = ({
    touches
  }: TouchEvent<HTMLDivElement>) => {
    if (!bodyRef.current || !headerRef.current || !deviceHeight) return;
    const touch = touches[0];
    const clientY = touch.clientY;
    if (clientY > deviceHeight / 2) {
      //remove to const
      dialogState.hide();
    }
    if (clientY < 30) {
      //remove to const
      return;
    }
    const translateY = `${clientY}px`;
    bodyRef.current.style.transform = `translateY(${translateY})`;
    headerRef.current.style.transform = `translateY(${+translateY / 2})`;
  };
  return <ModalContext.Provider value={{
    hide: dialogState.hide,
    baseId: dialogState.baseId
  }}>
        {!!disclosure && <DialogDisclosure {...dialogState} ref={disclosure.ref} {...disclosure.props || {}}>
            {(disclosureProps: DisclosureProps) => cloneElement(disclosure, disclosureProps)}
          </DialogDisclosure>}
        <DialogBackdrop {...dialogState} data-variant={variant} role="alertdialog" className={cx(cssDialogBackdrop, isSticky && cssIsSticky, classNameBackdrop)} onScroll={onScrollhandle}>
          <Dialog {...dialogState} preventBodyScroll={false} aria-label="dialog" tabIndex={0} unstable_finalFocusRef={element} unstable_autoFocusOnShow={false} unstable_autoFocusOnHide={false} hideOnClickOutside={hideOnClickOutside} className={cssDialog}>
            {(closeMode === "hide" || dialogState.visible || dialogState.animating) && <StyledDialogContent ref={bodyRef}>
                {isResponsive && <StyledHeaderDialogContent ref={headerRef} onTouchMove={dialogContentDraggingHandle} style={{
            width: `${width - 30}px`
          }}></StyledHeaderDialogContent>}
                <Header>
                  {title && <Heading>
                      <Typography variant={"h3"}>{title}</Typography>
                    </Heading>}
                  <Button variant={"box"} icon={"X"} className={cssButtonModalClose} onClick={() => {
              dialogState.hide();
            }} />
                </Header>
                {children}
              </StyledDialogContent>}
          </Dialog>
          {(dialogState.visible || dialogState.animating) && <StyledOverlay />}
        </DialogBackdrop>
      </ModalContext.Provider>;
});
Modal.displayName = "Modal";