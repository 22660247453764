import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/router"
import { useMutation, useQuery } from "react-query"
import {
  fetchAddCompares,
  fetchCompares,
  fetchRemoveAllCompares,
  fetchRemoveCompares,
} from "@/api/comparesAPI"
import { useAuth } from "@/hooks/auth"
import { BREAKPOINTS, BREAKPOINTS_NOT_AUTH } from "@/hooks/compares/constants"
import {
  getComparesExpiresStorage,
  getComparesSelectedCategoryStorage,
  getComparesStorage,
  setComparesExpiresStorage,
  setComparesSelectedCategoryCookie,
  setComparesStorage,
} from "@/hooks/compares/helpers"
import {
  AddProductPropsType,
  ComparesContentReturnType,
} from "@/hooks/compares/types"
import { NOTIFICATION_KIND } from "@/hooks/notification/constants"
import { useNotifications } from "@/hooks/notification/notification"
import { useAppDispatch, useAppSelector } from "@/hooks/redux"
import {
  addProductUUID,
  clearCompares,
  removeProductUUID,
  setComparesPayload,
  setSelectedCategory,
  setStorageExpiresDate,
  updateComparesPayloadByProduct,
} from "@/store/reducers/comparesSlice"
import { ROUTES } from "@/utils/constants"
import { dateToString, readBooleanFromStorage } from "@/utils/helpers"

export const ComparesContext = createContext<ComparesContentReturnType | null>(
  null,
)

const clearLocalStorage = () => {
  console.log("[clearStorage]")

  setComparesStorage([])
  setComparesExpiresStorage(null)
  setComparesSelectedCategoryCookie(null)
}

export const Provider: FC = ({ children }) => {
  const { isInit, isAuth } = useAuth()
  const { payload, products, selectedCategory, storageExpiresDate } =
    useAppSelector(({ compares }) => compares)
  const fetchedCategories = useAppSelector(
    ({ categories }) => categories.categories?.fetched,
  )
  const router = useRouter()
  const { push: pushNotification } = useNotifications()

  const isAccountPage =
    router.pathname === `${ROUTES.account}${ROUTES.compares}`
  const isDefaultPage = router.pathname === `${ROUTES.compares}`
  const [isShowToLogin, setIsShowToLogin] = useState(false)

  const refetchComparesEnabled = isAccountPage || isDefaultPage

  // если мы не находимся на странице сравнения
  // нет смысла запрашивать каждый раз compares
  const isRefetchComparesRef = useRef<boolean>(refetchComparesEnabled)

  // если добавлен новый товар, то обновляем активную категорию
  const lastAddedProductCategoryRef = useRef<string | null>(null)

  const dispatch = useAppDispatch()

  const storageExpiresProducts = useMemo(
    () => ({
      iso: !!storageExpiresDate
        ? dateToString(new Date(storageExpiresDate))
        : null,
    }),
    [storageExpiresDate],
  )

  const calcSelectedCategory = useCallback(
    (firstable = lastAddedProductCategoryRef.current): string | undefined => {
      return (
        firstable ||
        selectedCategory ||
        getComparesSelectedCategoryStorage() ||
        undefined
      )
    },
    [selectedCategory],
  )

  const { refetch: refetchCompares } = useQuery({
    queryKey: ["compares", isAuth],
    queryFn: () => fetchCompares(),
    enabled: isAuth,
    onSuccess: (data) => {
      console.log("compares client ", data)
      dispatch(
        setComparesPayload({
          response: data || [],
          selectedCategory: calcSelectedCategory(),
        }),
      )
    },
  })

  const refetchOnSingle = async () => {
    if (isRefetchComparesRef.current) {
      await refetchCompares()
    }
    isRefetchComparesRef.current = false
  }

  const { mutate: addMutate } = useMutation(fetchAddCompares, {
    onSuccess: (data, request) => {
      lastAddedProductCategoryRef.current = data?.category || null
      dispatch(addProductUUID(request.products.split(",")))
      void refetchOnSingle()
    },
  })

  const { mutate: removeMutate } = useMutation(fetchRemoveCompares, {
    onSuccess: (data, request) => {
      dispatch(removeProductUUID(request.products.split(",")))
    },
  })

  const { mutate: clearMutate, isLoading: clearIsLoading } = useMutation(
    fetchRemoveAllCompares,
    {
      onSuccess: () => {
        setComparesSelectedCategoryCookie(null)
        dispatch(clearCompares())
      },
    },
  )

  const selectCategory = useCallback(
    (uuid: string) => {
      dispatch(setSelectedCategory(uuid))
    },
    [dispatch],
  )

  const addProductHandle = useCallback(
    ({
      product,
      category,
      isRefetch = refetchComparesEnabled,
      withPush = true,
    }: AddProductPropsType & { isRefetch?: boolean; withPush?: boolean }) => {
      if (!isInit) {
        return
      }
      console.log("addProductHandle")
      if (!isAuth) {
        // без авторизации от товара мы берем
        // uuid и первую его категорию, потенциально ее может не быть, но это не логично
        // добавляем к остальным данным без перезаписи
        if (!category) {
          return
        }
        dispatch(
          updateComparesPayloadByProduct({
            product: product,
            category: {
              uuid: category,
              name: (fetchedCategories || {})[category]?.name || "",
              selected: calcSelectedCategory(category),
            },
          }),
        )
      } else {
        isRefetchComparesRef.current = isRefetch
        addMutate({
          products: product,
        })
      }

      if (!isAuth) {
        const isOfferToLogIn = readBooleanFromStorage(
          sessionStorage,
          "offerToLogInCompares",
        )

        if (!isOfferToLogIn) {
          sessionStorage.setItem("offerToLogInCompares", "true")
          setIsShowToLogin(true)
        }
      }

      if (withPush) {
        pushNotification({ kind: NOTIFICATION_KIND.COMPARES })
      }
    },
    [
      refetchComparesEnabled,
      isInit,
      isAuth,
      dispatch,
      fetchedCategories,
      calcSelectedCategory,
      addMutate,
      pushNotification,
    ],
  )

  const addSomeProducts = useCallback(
    (products: string[]) => {
      addProductHandle({
        product: products.join(","),
        withPush: false,
      })
    },
    [addProductHandle],
  )

  const removeProductHandle = useCallback(
    ({
      products,
      isRefetch = refetchComparesEnabled,
    }: {
      products: string[]
      isRefetch?: boolean
    }) => {
      if (!products.length) {
        return
      }

      if (isAuth) {
        isRefetchComparesRef.current = isRefetch
        removeMutate({
          products: products.join(","),
        })
      } else {
        dispatch(removeProductUUID(products))
      }
    },
    [isAuth, refetchComparesEnabled, removeMutate, dispatch],
  )

  const removeCategoryHandle: (uuid?: string) => void = useCallback(
    (uuid) => {
      const category = uuid || selectedCategory || undefined
      if (!payload || !category) {
        return
      }
      const foundCategory = payload[category]
      if (!foundCategory) {
        return
      }

      removeProductHandle({
        products: foundCategory.products,
      })
    },
    [payload, removeProductHandle, selectedCategory],
  )

  const breakpointsSlider = useMemo(
    () => (isAccountPage ? BREAKPOINTS : BREAKPOINTS_NOT_AUTH),
    [isAccountPage],
  )

  const updatePayloadStorage = useCallback(() => {
    const date = setComparesStorage(!payload ? payload : Object.values(payload))
    if (!!date) {
      dispatch(setStorageExpiresDate(date.toISOString()))
    }
  }, [payload, dispatch])

  const initHandleNotAuth = useCallback(() => {
    dispatch(
      setComparesPayload({
        response: getComparesStorage() || [],
      }),
    )

    dispatch(setStorageExpiresDate(getComparesExpiresStorage()))

    const initialSelectedCategory = calcSelectedCategory()
    if (!!initialSelectedCategory) {
      selectCategory(initialSelectedCategory)
    }
  }, [calcSelectedCategory, dispatch, selectCategory])

  const clear = useCallback(() => {
    if (!isInit) return

    if (isAuth) {
      clearMutate()
    } else {
      clearLocalStorage()
      dispatch(clearCompares())
      dispatch(setStorageExpiresDate(null))
    }
  }, [isInit, isAuth, clearMutate, dispatch])

  const hideToLogin = useCallback(() => {
    setIsShowToLogin(false)
  }, [setIsShowToLogin])

  useEffect(() => {
    if (isInit && !isAuth) {
      updatePayloadStorage()
    }
  }, [updatePayloadStorage, isAuth, isInit])

  useEffect(() => {
    if (isInit && !isAuth) {
      initHandleNotAuth()
    }
  }, [initHandleNotAuth, isAuth, isInit])

  useEffect(() => {
    if (!!selectedCategory) {
      setComparesSelectedCategoryCookie(selectedCategory)
    }
  }, [selectedCategory])

  useEffect(() => {
    if (!isInit || !isAuth) {
      return
    }

    // во время авторизации достаем товары для сравнения
    const comparesStorage = getComparesStorage()

    if (!comparesStorage || !comparesStorage.length) {
      return
    }
    console.log("clearStorage")

    // стираем локальное хранилище
    clearLocalStorage()

    // собираем массив уникальных uuid товаров для добавления в избранное
    const products: Set<string> = new Set(
      comparesStorage.reduce(
        (arr: string[], item) => [...arr, ...item.products],
        [],
      ),
    )

    addSomeProducts([...products])
  }, [addSomeProducts, dispatch, isAuth, isInit])

  const value = useMemo(
    () =>
      ({
        addProduct: addProductHandle,
        removeProduct: removeProductHandle,
        payload,
        products,
        quantity: products.uuids.length,
        removeCategory: removeCategoryHandle,
        clear: clear,
        clearIsLoading: clearIsLoading,
        selectCategory,
        breakpointsSlider,
        storageExpiresProducts,
        refetchCompares: refetchCompares,
        isShowToLogin,
        hideToLogin,
      } as ComparesContentReturnType),
    [
      addProductHandle,
      removeProductHandle,
      payload,
      products,
      removeCategoryHandle,
      clear,
      clearIsLoading,
      selectCategory,
      breakpointsSlider,
      storageExpiresProducts,
      refetchCompares,
      isShowToLogin,
      hideToLogin,
    ],
  )
  return (
    <ComparesContext.Provider value={value}>
      {children}
    </ComparesContext.Provider>
  )
}
