import React, { FC, memo } from "react"
import { Link } from "../Link"
import { NavigationTitlePropsType } from "./types"
import { PushCounter } from "@/components/PushCounter"

const NavigationTitle: FC<NavigationTitlePropsType> = memo(
  ({ title, path, variant, isLink, isScroll, qty }) => {
    return (
      <>
        {!!isLink ? (
          <Link variant={variant} href={path} scroll={isScroll} title={title}>
            {title}
            {!!qty && (
              <>
                &nbsp;
                <PushCounter qty={qty} />
              </>
            )}
          </Link>
        ) : (
          <>{title}</>
        )}
      </>
    )
  },
)

NavigationTitle.displayName = "NavigationTitle"
export { NavigationTitle }
