import { FAVORITES_LOCAL_STORAGE_KEY } from "@/hooks/favorites/constants"

export const getFavoritesStorage = (): string[] | null => {
  const favoritesKeys = localStorage.getItem(FAVORITES_LOCAL_STORAGE_KEY)
  return !!favoritesKeys ? JSON.parse(favoritesKeys) : null
}
export const setFavoritesStorage = (favoritesKeys: string[] | null) => {
  if (favoritesKeys === null) {
    return
  }

  if (favoritesKeys.length < 1) {
    localStorage.removeItem(FAVORITES_LOCAL_STORAGE_KEY)
    return
  }

  localStorage.setItem(
    FAVORITES_LOCAL_STORAGE_KEY,
    JSON.stringify(favoritesKeys),
  )
}
