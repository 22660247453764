export const fontDefaultName = `montserrat`
export const fontDefault = `${fontDefaultName}, sans-serif`
export const fontSizeDefault = `16px`
export const colors = {
  black: `#000000`,
  white: `#FFFFFF`,
  transparentWhite: `rgba(255, 255, 255, 0.1)`,
  transparentWhite7: `rgba(255, 255, 255, 0.7)`,
  transparentBlack: `rgba(0, 0, 0, 0.8)`,
  transparentBlack1: `rgba(0, 0, 0, 0.1)`,
  gray: `#DEDEDE`,
  grayLight: `#F5F5F5`,
  grayLighter: `#fcfcfc`,
  grayDarkLight: `#a9a9a9`,
  grayDark: `#7A7A7A`,
  grayDarker: `#3b3b3b`,
  pink: `#F5D7FF`,
  pinkLight: `#F8EBFC`,
  blueLight: `#ECFFEF`,
  red: `#FF003D`,
  redTransparent1: `rgba(255, 0, 61, 0.1)`,
  green: `#26AA6B`,
  greenLight: `#5CB149`,
  yellowLight: `#FEE34E`,
  brand: {
    purple: `#BC39E5`,
    purpleTransparent0: `rgba(188, 57, 229, 0)`,
    purpleTransparent15: `rgba(188, 57, 229, 0.15)`,
    purpleTransparent7: `rgba(188, 57, 229, 0.7)`,
    purpleDarken: `#9016B6`,
    yellow: `#FECC00`,
    yellowLight: `#FFE53D`,
    yellowTransparent15: `rgba(254, 204, 0, 0.15)`,
    orange: `#FC6631`,
    orangeTransparent1: `rgba(252, 102, 49, 0.1)`,
    blue: `#17D6FF`,
    blueTransparent1: `rgba(23, 214, 255, 0.1)`,
    green: `#40FD5C`,
  },
  social: {
    vk: `#0057FF`,
    tg: `#408CD2`,
  },
}
export const transitionTimingFunction = "cubic-bezier(0.22, 0.61, 0.36, 1)"
export const transitionDefault = `all .1s ${transitionTimingFunction}`

export const sizeSVG = {
  largeXL: "100px",
  largeL: "60px",
  largeM: "32px",
  large: "24px",
  default: "20px",
  mediumM: "18px",
  medium: "16px",
  small: "14px",
  smaller: "12px",
  fill: "100%",
} as const
export const borderRadiusControl = "4px"
export type TypographyVariantsType =
  | "h1"
  | "h2"
  | "h3"
  | "h5"
  | "h6"
  | "p10"
  | "p12"
  | "p13"
  | "p14"
  | "p16"
  | "span"
  | "default"
type typographyStyleType = {
  [key in TypographyVariantsType]: {
    fs: string
    lh: string
    fw: string | number
  }
}
export const typography: typographyStyleType = {
  h1: { fs: "1.62rem", lh: "131%", fw: "bold" },
  h2: { fs: "1.25rem", lh: "170%", fw: 500 },
  h3: { fs: "1rem", lh: "150%", fw: "bold" },
  h5: { fs: "0.88rem", lh: "200%", fw: "bold" },
  h6: { fs: "1.13rem", lh: "156%", fw: 500 },
  p16: { fs: "1rem", lh: "150%", fw: 500 },
  p14: { fs: ".88rem", lh: "200%", fw: 500 },
  p13: { fs: ".80rem", lh: "150%", fw: 500 },
  p12: { fs: ".75rem", lh: "200%", fw: 500 },
  p10: { fs: ".63rem", lh: "140%", fw: 500 },
  span: { fs: "1rem", lh: "150%", fw: 500 },
  default: { fs: "1rem", lh: "187%", fw: 500 },
}
export const boxRadio = {
  container: { w: "36px", h: "20px" },
  target: { w: "16px", h: "16px" },
}
export const boxCheck = {
  container: { w: "17px", h: "17px" },
  target: { w: "7px", h: "7px" },
}
export const breakpoints = {
  xs: "375px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1456px",
  xxxl: "1600px",
} as const

export const ANIMATED_TRANSITION_DEFAULT = 300

export const BoxShadowPopover = `rgba(17, 17, 26, 0.1) 0 4px 16px,
      rgba(17, 17, 26, 0.1) 0 8px 24px, rgba(17, 17, 26, 0.1) 0 16px 56px`
