import { createContext, FC } from "react"
import { ModalsContextReturnType } from "@/hooks/modals/types"
import { useAppDispatch } from "@/hooks/redux"
import { modalsAlias, setIsShowModal } from "@/store/reducers/commonSlice"

export const ModalsContext = createContext<ModalsContextReturnType | null>(null)

export const Provider: FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const _toggledShow = (payload: { alias: modalsAlias; isShow: boolean }) => {
    dispatch(setIsShowModal(payload))
  }
  const show = ({ alias }: { alias: modalsAlias }) => {
    _toggledShow({
      alias,
      isShow: true,
    })
  }

  const hide = ({ alias }: { alias: modalsAlias }) => {
    _toggledShow({
      alias,
      isShow: false,
    })
  }

  const value = {
    show,
    hide,
  }

  return (
    <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>
  )
}
