import { FC } from "react"
import { DigiCategoryTypeAutoComplete } from "../../../../api/digineticaAPI"
import { Trigger } from "../../../../styles/utils/Utils"
import { Typography } from "../../../ui/Typography/Typography"
import { StyledCategory } from "./StyledCategory"

export const Category: FC<{
  category: DigiCategoryTypeAutoComplete
  updateCategorySearch: (props: { cId: string; title: string }) => void
}> = ({ category, updateCategorySearch }) => {
  return (
    <>
      <StyledCategory data-id={category.id}>
        <Typography
          variant={"p14"}
          onClick={() => {
            updateCategorySearch({
              cId: category.id,
              title: category.name,
            })
          }}
        >
          <Trigger>{category.name}</Trigger>
        </Typography>
      </StyledCategory>
    </>
  )
}
