import { type FC } from "react";
import { StyledNotification, StyledNotificationWrapper, StyledNotificationLeft, StyledNotificationRight, cssCloseButton } from "./StyledNotification";
import { Button } from "@/components/ui/Button/Button";
import { Typography } from "@/components/ui/Typography/Typography";
import { useNotifications } from "@/hooks/notification/notification";
import { ROUTES } from "@/utils/constants";
type NotificationPropsType = {
  index: number;
  kind: "favorites" | "compares" | "cart";
};
const getTextFromKind = (kind: "favorites" | "compares" | "cart") => {
  switch (kind) {
    case "favorites":
      return "Товар добавлен в избранное";
    case "compares":
      return "Товар добавлен к сравнению";
    case "cart":
      return "Товар добавлен в корзину";
  }
};
const getHrefFromKind = (kind: "favorites" | "compares" | "cart") => {
  switch (kind) {
    case "favorites":
      return ROUTES.favorites;
    case "compares":
      return ROUTES.compares;
    case "cart":
      return ROUTES.cart;
  }
};
const getButtonContentFromKind = (kind: "favorites" | "compares" | "cart") => {
  switch (kind) {
    case "favorites":
      return "Перейти в избранное";
    case "compares":
      return "Перейти к сравнению";
    case "cart":
      return "Перейти в корзину";
  }
};
export const Notification: FC<NotificationPropsType> = ({
  index,
  kind
}) => {
  const {
    hide
  } = useNotifications();
  return <StyledNotificationWrapper>
      <StyledNotificationLeft>
        <Button variant={"box"} icon={"X"} className={cssCloseButton} onClick={() => {
        hide({
          index
        });
      }} />
      </StyledNotificationLeft>
      <StyledNotificationRight>
        <StyledNotification>
          <Typography>{getTextFromKind(kind)}</Typography>
          <Button variant="filled" as="a" href={getHrefFromKind(kind)}>
            {getButtonContentFromKind(kind)}
          </Button>
        </StyledNotification>
      </StyledNotificationRight>
    </StyledNotificationWrapper>;
};