import { ComponentType, FC, memo, useState } from "react"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import LazyHydrate from "react-lazy-hydration"
import { BannerHeaderPropsType } from "../types"
import { StyledBannerContainer } from "./StyledBannerHeader"
import { lazyHydrationConfig } from "@/hooks/lazyHydrate"
import { FormConfigExtendsType } from "@/types/types"
import { Button } from "@/ui/Button/Button"
import { Modal } from "@/ui/Modal/Modal"
import { Typography } from "@/ui/Typography/Typography"

const SendCustomMailForm: ComponentType<FormConfigExtendsType> = dynamic(() =>
  import("@/components/Forms/SendCustomMail").then((mod) => mod.SendCustomMail),
)

export const BannerHeader: FC<BannerHeaderPropsType> = memo(
  ({ variantTypography = "h5", text, href }) => {
    const router = useRouter()
    const [isShowModal, setIsShowModal] = useState(false)

    return (
      <>
        <StyledBannerContainer>
          <Button
            onClick={() => setIsShowModal(true)}
            aria-label={text}
            seoText={text}
          />
          <Typography variant={variantTypography}>{text}</Typography>
        </StyledBannerContainer>
        <Modal
          title={"Прежде чем перейти"}
          variant={"rounded-0"}
          closeMode={"destroy"}
          isShowModal={isShowModal}
          onClose={() => {
            setIsShowModal(false)
          }}
        >
          <LazyHydrate whenVisible={lazyHydrationConfig}>
            <SendCustomMailForm
              successMessage={
                "Благодарим! Сейчас Вы будете направлены на старую версию сайта!"
              }
              cbOnSubmit={() => {
                void router.push(href)
              }}
            />
          </LazyHydrate>
        </Modal>
      </>
    )
  },
)

BannerHeader.displayName = "BannerHeader"
