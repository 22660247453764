import { EntityStoreType, GetUnitsType } from "./types"
import { ProductWithChildType } from "@/components/Products/types"
import { RadioGroupItemsType, SpecificationItemType } from "@/types"
import { ROUTES } from "@/utils/constants"

export const _unitsSortAsc = (
  units: RadioGroupItemsType[],
): RadioGroupItemsType[] =>
  [...units].sort((a, b) => {
    const valA = a.value || 0
    const valB = b.value || 0
    return valA > valB ? 1 : valA < valB ? -1 : 0
  })

export const _unitsSortDesc = (
  units: RadioGroupItemsType[],
): RadioGroupItemsType[] =>
  [...units].sort((a, b) => {
    const valA = a.value || 0
    const valB = b.value || 0
    return valA > valB ? -1 : valA < valB ? 1 : 0
  })

export const initialState = {
  product: null as ProductWithChildType | null,
  isInit: false as boolean,
  unit: null as number | null,
  count: null as number | null,
  counter: null as number | null,
  sampleCount: 0 as number,
  units: null as RadioGroupItemsType[] | null,
  inCart: false as boolean,
  currentSpecification: null as SpecificationItemType | null,
  totalQty: 0 as number,
  isRemoved: false as boolean,
  isAnimate: null as boolean | null,
  isCountError: false as boolean,
  containersIsOnly: false as boolean,
  unitMeasure: null as string | null,
  isVariative: false as boolean,
  path: null as string | null,
  uuid: null as string | null,
  images: [] as string[],
  stores: [] as EntityStoreType[],
  storesAvailable: [] as EntityStoreType[],
}

export const DEFAULT_QTY = 1

export const DEFAULT_UNIT_MEASURE = "шт"

export const getCurrentSpecification = ({
  specification,
  uuid,
  parent,
}: {
  specification: Record<string, SpecificationItemType> | null
  parent?: string
  uuid: string
}): SpecificationItemType | null => {
  if (!specification) {
    return null
  }

  if (!!parent) {
    return (specification[parent].child || {})[uuid || ""] || {}
  } else {
    return specification[uuid] || {}
  }
}

export const getUnits: GetUnitsType = ({
  unitMeasure,
  units,
  isOnlyContainers,
  parentKit,
}) => {
  let u: RadioGroupItemsType[] = []
  if (units === null) {
    return { units: null }
  }

  if (!parentKit) {
    u = [
      ...u,
      ...units.map((u) => ({
        value: u.value || "",
        message: `${u.value} в ${u.name}`,
      })),
    ]
  }

  if (!isOnlyContainers || !!parentKit) {
    u = [
      ...u,
      {
        value: DEFAULT_QTY,
        message: `${DEFAULT_QTY} ${unitMeasure || "шт"}`,
      },
    ]
  }

  return { units: _unitsSortAsc(u) }
}

export const getIsKit = (kit?: string[]) => (kit || []).length > 0

export const getPath = (alias?: string) =>
  alias === undefined ? null : `${ROUTES.product}/${alias}`

export const getImages = (images?: string[]) =>
  (images || []).reduce((uniq: string[], item) => {
    return uniq.includes(item) ? uniq : [...uniq, item]
  }, [])

export const removeZeroStart = (numStr: string) => {
  let s = numStr
  while (s.charAt(0) === "0") {
    s = s.substring(1)
  }
  return s
}
